import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button, FormControl, FormLabel, Input, Spinner } from '@chakra-ui/react';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useEstado } from '../contexts/EstadoContext';
import validationSchema from '../variables/Validation';
import { useToastHook } from 'components/toast';

const EditarFormModal = ({ id, editarModal }) => {

  const { fetchEstadoId } = useEstado();
  
  let [estado, setEstado] = useState([]);
  
  async function fetchData() {
    const responseData = await fetchEstadoId(id)
    setEstado(responseData)
  }

  useEffect(() => {
    if (editarModal.isOpen) {
      fetchData()
    }
  }, [editarModal.isOpen]);
  
  if (estado.length === 0) return;
  
  return (
    <>
      {/* Modal com o formulário de Atualização */}
      <Modal
        isOpen={editarModal.isOpen}
        onClose={editarModal.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={estado}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));

                console.log(values);

                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <ModalBody pb={6}>
                  {/* Nome */}
                  <FormControl>
                    <FormLabel htmlFor="nome">Nome</FormLabel>
                    <Input
                      type="text"
                      name="nome"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome}
                    />
                    <ErrorMessage name="nome" component="div" />
                  </FormControl>

                  {/* Sigla */}
                  <FormControl>
                    <FormLabel htmlFor="uf">Sigla</FormLabel>
                    <Input
                      type="text"
                      name="uf"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.uf}
                    />
                    <ErrorMessage name="uf" component="div" />
                  </FormControl>

                  {/* Região */}
                  <FormControl>
                    <FormLabel htmlFor="regiao">Região</FormLabel>
                    <Input
                      type="text"
                      name="regiao"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.regiao}
                    />
                    <ErrorMessage name="regiao" component="div" />
                  </FormControl>

                  {/* Latitude */}
                  <FormControl>
                    <FormLabel htmlFor="latitude">Latitude</FormLabel>
                    <Input
                      type="text"
                      name="latitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.latitude}
                    />
                    <ErrorMessage name="latitude" component="div" />
                  </FormControl>

                  {/* Longitude */}
                  <FormControl>
                    <FormLabel htmlFor="longitude">Longitude</FormLabel>
                    <Input
                      type="text"
                      name="longitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.longitude}
                    />
                    <ErrorMessage name="longitude" component="div" />
                  </FormControl>

                  {/* Fuso horário */}
                  <FormControl>
                    <FormLabel htmlFor="codigo_uf">Código de origem</FormLabel>
                    <Input
                      type="text"
                      name="codigo_uf"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.codigo_uf}
                    />
                    <ErrorMessage name="codigo_uf" component="div" />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    colorScheme="blue"
                    mr={3}
                  >
                    Salvar
                  </Button>
                  <Button onClick={editarModal.onClose}>Cancelar</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarFormModal;
