import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import axiosClient from 'api/soutecapi';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import validationSchema from '../variables/Validation';

const AdicionarFormModal = ({ adicionarModal }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  let [cidade, setCidade] = useState({});
  let [estados, setEstados] = useState([]);

  async function fetchEstados() {
    let response = await axiosClient.get('/estados');
    let estados = response.data.data;
    setEstados([...estados]);
  }

  useEffect(() => {
    fetchEstados();
  }, []);

  return (
    <>
      {/* Modal com o formulário de Atualização */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={adicionarModal.isOpen}
        onClose={adicionarModal.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={cidade}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));

                console.log(values);

                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <ModalBody pb={6}>
                  {/* Nome */}
                  <FormControl>
                    <FormLabel htmlFor="nome">Nome</FormLabel>
                    <Input
                      type="text"
                      name="nome"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome}
                    />
                    <ErrorMessage name="nome" component="div" />
                  </FormControl>

                  {/* Capital */}
                  <Stack spacing={5} direction="row">
                    <Checkbox
                      name="capital"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isChecked={values.capital}
                      value={values.capital}
                    >
                      Capital?
                    </Checkbox>
                    <ErrorMessage name="capital" component="div" />
                  </Stack>

                  {/*TODO: Estado */}
                  <Field name="estado">
                    {({ field }) => (
                      <FormControl>
                        <FormLabel htmlFor="estado">Estado</FormLabel>
                        <Select
                          value={values.estado}
                          onChange={handleChange}
                          name="estado"
                        >
                          {estados.map((estado) => (
                            <option
                              value={estado.id}
                              selected={values.estado.id === estado.id}
                              key={estado.id}
                            >
                              {estado.nome}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage name="estado" component="div" />
                      </FormControl>
                    )}
                  </Field>

                  {/* Latitude */}
                  <FormControl>
                    <FormLabel htmlFor="latitude">Latitude</FormLabel>
                    <Input
                      type="text"
                      name="latitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.latitude}
                    />
                    <ErrorMessage name="latitude" component="div" />
                  </FormControl>

                  {/* Longitude */}
                  <FormControl>
                    <FormLabel htmlFor="longitude">Longitude</FormLabel>
                    <Input
                      type="text"
                      name="longitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.longitude}
                    />
                    <ErrorMessage name="longitude" component="div" />
                  </FormControl>

                  {/* Fuso horário */}
                  <FormControl>
                    <FormLabel htmlFor="fuso_horario">Fuso horário</FormLabel>
                    <Input
                      type="text"
                      name="fuso_horario"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fuso_horario}
                    />
                    <ErrorMessage name="fuso_horario" component="div" />
                  </FormControl>

                  {/* DDD */}
                  <FormControl>
                    <FormLabel htmlFor="ddd">DDD</FormLabel>
                    <Input
                      type="text"
                      name="ddd"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ddd}
                    />
                    <ErrorMessage name="ddd" component="div" />
                  </FormControl>

                  {/* Código IBGE */}
                  <FormControl>
                    <FormLabel htmlFor="codigo_ibge">Código IBGE</FormLabel>
                    <Input
                      type="text"
                      name="codigo_ibge"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.codigo_ibge}
                    />
                    <ErrorMessage name="codigo_ibge" component="div" />
                  </FormControl>

                  {/* Siafi */}
                  <FormControl>
                    <FormLabel htmlFor="siafi_id">Siafi</FormLabel>
                    <Input
                      type="text"
                      name="siafi_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.siafi_id}
                    />
                    <ErrorMessage name="siafi_id" component="div" />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    colorScheme="blue"
                    mr={3}
                  >
                    Salvar
                  </Button>
                  <Button onClick={adicionarModal.onClose}>Cancelar</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdicionarFormModal;
