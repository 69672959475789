import { Button } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import React from 'react';
import { MdAdd } from 'react-icons/md';

const AddButton = ({ handleClick }) => {
  return (
    <Tooltip label="Adicionar">
      <Button onClick={handleClick} variant="solid" colorScheme="blue">
        <MdAdd />
      </Button>
    </Tooltip>
  );
};

export default AddButton;
