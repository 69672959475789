import Card from 'components/card';
import SearchTableCidades from './components/SearchTableCidades';
import { CidadeContextProvider } from './contexts/CidadeContext';

const Index = () => {
  return (
    <Card extra={'w-full h-full bg-white mt-3'}>
      <CidadeContextProvider>
        {/* Listagem de Cidade */}
        <SearchTableCidades></SearchTableCidades>
      </CidadeContextProvider>
    </Card>
  );
};

export default Index;
