import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
} from '@chakra-ui/react';
import { ErrorMessage, Form, Formik } from 'formik';
import { useAuth } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

import Card from 'components/card';
import Centered from 'layouts/auth/types/Centered';
import Soutec from 'assets/img/soutec/horizontal.png';
import validationSchema from '../variables/Validation';
import PasswordInputField from 'components/fields/PasswordInputField';

function SignInCenter() {
  const navigate = useNavigate();

  let [signIn, setSignIn] = useState({
    email: '',
    password: '',
  });

  const { handleSignIn, signed } = useAuth();

  useEffect(() => {
    if (signed) {
      navigate('/admin');
    }
  }, [signed, navigate]);

  return (
    <Centered
      bgImage="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <Image src={Soutec} alt="Logotipo do Soutec" />
          <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
            Login
          </h3>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Informe seu e-mail e senha para acessar!
          </p>

          {/* Formik */}
          <Formik
            initialValues={signIn}
            validationSchema={validationSchema}
            onSubmit={handleSignIn}
            enableReinitialize={true}
          >
            {({
              values,
              setValues,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                {/* E-mail */}
                <FormControl isRequired>
                  <FormLabel htmlFor="email">E-mail</FormLabel>
                  <Input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Digite o e-mail."
                  />
                  <FormHelperText>
                    <ErrorMessage name="email" />
                  </FormHelperText>
                </FormControl>

                {/* Password - Senha */}
                <FormControl isRequired>
                  <FormLabel htmlFor="password">Senha</FormLabel>
                  <PasswordInputField
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  ></PasswordInputField>
                  <FormHelperText>
                    <ErrorMessage name="password" />
                  </FormHelperText>
                </FormControl>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="right"
                  width="100%"
                  w="100%"
                >
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    colorScheme="blue"
                    mt={3}
                    w="100%"
                  >
                    Acessar
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Card>
      }
    />
  );
}

export default SignInCenter;
