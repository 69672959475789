import { MapContainer } from 'react-leaflet';

import MakerRecenter from './MakerRecenter';

const MakerMap = ({ position, zoomLevel = 15 }) => {
  return (
    <MapContainer center={position} zoom={zoomLevel} scrollWheelZoom={false}>
      <MakerRecenter position={position} />
    </MapContainer>
  );
};

export default MakerMap;
