import { useDisclosure } from '@chakra-ui/hooks';
import { createContext, useState, useContext } from 'react';
import axiosClient from 'api/soutecapi';

export const InstituicaoEnsinoContext = createContext({});

export function InstituicaoEnsinoContextProvider({ children }) {
  const noSelectIdInstituicaoEnsino = 0;

  const emptyInstituicoesEnsino = {
    id: 0,
    nome: '',
    cnpj: '',
    codEscola: '',
    codInep: '',
    latitude: '',
    longitude: '',
    email: '',
    fax: '',
    possuiAutonomiaCriacaoCursos: false,
    telefone1: '',
    telefone2: '',
    dataPublicacaoAtoAutoritativo: '',
    numeroAtoAutoritativo: '',
    tipoAtoAutoritativo: '',
    sistema_ensino: '',
    tipo_escola: '',
    situacao_ativo: '',
    dependencia: '',
    subdependencia: '',
    orgao_nome: '',
    situacao_deferimento: '',
    data_deferimento: '',
    endereco: {
      id: 0,
      bairro: '',
      cep: '',
      logradouro: '',
      municipio: '',
      numero: '',
      uf: '',
    },
  };

  // Instituições de Ensino
  const [instituicoesEnsino, setInstituicoesEnsino] = useState([]);

  // Instituições de Ensino selecionada.
  const [selectedInstituicaoEnsino, setSelectedInstituicaoEnsino] = useState(
    emptyInstituicoesEnsino
  );

  // Id da Instituições de Ensino selecionada.
  const [selectedIdInstituicaoEnsino, setSelectedIdInstituicaoEnsino] =
    useState(0);

  // Modal com formulário de edição.
  const editarModal = useDisclosure();

  // Modal com formulário de edição.
  const removerModal = useDisclosure();

  // Modal com formulário de edição.
  const cursosModal = useDisclosure();

  // Modal com exibição do mapa.
  const exibirMapaModal = useDisclosure();

  const fetchInstituicoesEnsino = async () => {
    try {
      let response = await axiosClient.get('/instituicoesensino');
      let data = await response.data.data;
      setInstituicoesEnsino([...data]);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  const getDataInstituicoesEnsinoPagination = async (page = 1, perPage = 10) => {
    try {
      let response = await axiosClient.get(
        `/instituicoesensino/?page=${page}&limit=${perPage}`
      );
      let data = await response.data;
      return data;
    } catch (error) {
      console.log(error.response);
    }
  };

  const fetchInstituicaoEnsino = async () => {
    try {
      let response = await axiosClient.get(
        `/instituicoesensino/${selectedIdInstituicaoEnsino}`
      );
      let data = await response.data.data;
      setSelectedInstituicaoEnsino([...data]);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  const updateInstituicaoEnsino = async (values) => {
    let data = emptyInstituicoesEnsino;

    try {
      // Atualizar.
      let response = await axiosClient.put(
        `/cidades/${selectedIdInstituicaoEnsino}`,
        values
      );
      data = await response.data.data;
    } catch (error) {
      console.error('Error fetching:', error);
    }

    //Retornar item atualizado.
    return data;
  };

  const updateItensTableInstituicaoEnsino = (novaInstituicaoEnsino) => {
    const novasInstituicoesEnsino = instituicoesEnsino.map(
      (itemInstituicaoEnsino) => {
        return itemInstituicaoEnsino.id === novaInstituicaoEnsino.id
          ? { ...novaInstituicaoEnsino }
          : itemInstituicaoEnsino;
      }
    );

    setInstituicoesEnsino([...novasInstituicoesEnsino]);
  };

  const handleSubmitUpdateInstituicaoEnsinoForm = async (
    values,
    { setSubmitting }
  ) => {
    // Atualizar os dados no endpoint do servidor.
    let novaCidade = await updateInstituicaoEnsino(values);

    // Atualizar listagem
    updateItensTableInstituicaoEnsino(novaCidade);

    //Reiniciar formulário.
    setSelectedIdInstituicaoEnsino(noSelectIdInstituicaoEnsino);

    // Fechar modal
    editarModal.onClose();
  };

  const handleLoadUpdateInstituicaoEnsinoForm = (id) => {
    // Item selecionado.
    setSelectedIdInstituicaoEnsino(id);

    // Exibir Modal do formulário.
    editarModal.onOpen();
  };

  const handleLoadDeleteInstituicaoEnsinoModal = (id) => {
    // Item selecionado.
    setSelectedIdInstituicaoEnsino(id);

    // Exibir Modal de remoção.
    removerModal.onOpen();
  };

  const handleLoadCursosModal = (id) => {
    // Item selecionado.
    setSelectedIdInstituicaoEnsino(id);

    // Exibir Modal de remoção.
    cursosModal.onOpen();
  };

  const handleLoadMapCidadeModal = (id) => {
    // Item selecionado.
    setSelectedIdInstituicaoEnsino(id);

    // Exibir Modal de exibição do mapa.
    exibirMapaModal.onOpen();
  }

  return (
    <InstituicaoEnsinoContext.Provider
      value={{
        emptyInstituicoesEnsino,
        instituicoesEnsino,
        setInstituicoesEnsino,
        selectedInstituicaoEnsino,
        setSelectedInstituicaoEnsino,
        selectedIdInstituicaoEnsino,
        setSelectedIdInstituicaoEnsino,
        editarModal,
        removerModal,
        cursosModal,
        fetchInstituicoesEnsino,
        fetchInstituicaoEnsino,
        handleLoadUpdateInstituicaoEnsinoForm,
        handleLoadDeleteInstituicaoEnsinoModal,
        handleLoadCursosModal,
        handleSubmitUpdateInstituicaoEnsinoForm,
        getDataInstituicoesEnsinoPagination,
        exibirMapaModal,
        handleLoadMapCidadeModal
      }}
    >
      {children}
    </InstituicaoEnsinoContext.Provider>
  );
}

export function useInstituicaoEnsino() {
  return useContext(InstituicaoEnsinoContext);
}
