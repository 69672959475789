import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button, FormControl, FormLabel, Input } from '@chakra-ui/react';
import axiosClient from 'api/soutecapi';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useInstituicaoEnsino } from '../contexts/InstituicaoEnsinoContext';
import validationSchema from '../variables/Validation';

const EditarFormModal = () => {
  const {
    emptyInstituicoesEnsino,
    selectedIdInstituicaoEnsino,
    editarModal,
    handleSubmitUpdateInstituicaoEnsinoForm,
  } = useInstituicaoEnsino();

  let [instituicaoEnsino, setInstituicaoEnsino] = useState(
    emptyInstituicoesEnsino
  );

  // const fetchInstituicaoEnsino = async () => {
  //   try {
  //     let response = await axiosClient.get(
  //       `/instituicoesensino/${selectedIdInstituicaoEnsino}`
  //     );
  //     let data = await response.data.data;
  //     setInstituicaoEnsino({ ...data });
  //   } catch (error) {
  //     console.error('Error fetching todos:', error);
  //   }
  // };

  // useEffect(() => {
  //   // Instituição Ensino
  //   selectedIdInstituicaoEnsino !== 0
  //     ? fetchInstituicaoEnsino()
  //     : setInstituicaoEnsino(emptyInstituicoesEnsino);
  // }, [selectedIdInstituicaoEnsino]);

  return (
    <>
      {/* Modal com o formulário de Atualização */}
      <Modal
        isOpen={editarModal.isOpen}
        onClose={editarModal.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={instituicaoEnsino}
            validationSchema={validationSchema}
            onSubmit={handleSubmitUpdateInstituicaoEnsinoForm}
            enableReinitialize={true}
          >
            {({
              values,
              setValues,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <ModalBody pb={6}>
                  {/* Nome */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="nome">Nome</FormLabel>
                    <Input
                      type="text"
                      name="nome"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome}
                    />
                    <ErrorMessage name="nome" component="div" />
                  </FormControl>

                  {/* Latitude */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="latitude">Latitude</FormLabel>
                    <Input
                      type="text"
                      name="latitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.latitude}
                    />
                    <ErrorMessage name="latitude" component="div" />
                  </FormControl>

                  {/* Longitude */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="longitude">Longitude</FormLabel>
                    <Input
                      type="text"
                      name="longitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.longitude}
                    />
                    <ErrorMessage name="longitude" component="div" />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    colorScheme="blue"
                    mr={3}
                  >
                    Salvar
                  </Button>
                  <Button onClick={editarModal.onClose}>Cancelar</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarFormModal;
