import { Button } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import React from 'react';
import { RiMapPin2Fill } from 'react-icons/ri';

const MapButton = ({ handleClick }) => {
  return (
    <Tooltip label="Mapa">
      <Button onClick={handleClick} colorScheme="gray">
        <RiMapPin2Fill />
      </Button>
    </Tooltip>
  );
};

export default MapButton;
