import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import DataTablePaginationRemote from 'components/table/DataTablePaginationRemote';
import { useCurso } from '../contexts/CursoContext';
import { columnsData } from '../variables/columnsData';
import AdicionarFormModal from './AdicionarFormModal';
import AddButton from 'components/button/AddButton';

const SearchTable = () => {
  const { getData } = useCurso();

  // Modal com formulário de atualização.
  const adicionarModal = useDisclosure();

  const handleClickAdicionar = (event) => {
    adicionarModal.onOpen();
  };

  return (
    <div className="h-full w-full">
      {/* Busca */}
      <Flex>
        <Stack className="mx-4 mt-4" direction="row" spacing={4}>
          <AddButton handleClick={handleClickAdicionar} />
          <AdicionarFormModal adicionarModal={adicionarModal} />
        </Stack>
      </Flex>

      {/* table e a paginação */}
      <div className="mt-11 h-full w-full overflow-x-scroll xl:overflow-hidden">
        <DataTablePaginationRemote
          title={'Cursos'}
          getData={getData}
          columns={columnsData}
        />
      </div>
    </div>
  );
};

export default SearchTable;
