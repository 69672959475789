import axios, { HttpStatusCode } from 'axios';

// Adicionar a busca no endpoint.
const baseURL = process.env.REACT_APP_BASE_URL_API + '/api/v1';

const soutecapi = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  },
});

const setAuthorizationToken = (config) => {
  const token = localStorage.getItem('soutec@auth:token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
};

soutecapi.interceptors.request.use(
  (config) => {
    setAuthorizationToken(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

soutecapi.interceptors.response.use(
  function (response) {
    // Response - 2xx.
    return response;
  },

  function (error) {
    // Response - 4xx ou 5xx.
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === HttpStatusCode.Unauthorized
    ) {
      // Limpar localstorage.
      localStorage.clear();

      // Redirecionar para o login
      window.location.pathname = '/auth/sign-in';
      //globalRouter.navigate('/auth/sign-in');

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default soutecapi;
