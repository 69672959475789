import OpcoesTrTable from '../components/OpcoesTrTable';

export const columnsData = [
  {
    name: 'Estado',
    selector: (row) => row.nome,
    sortable: true,
  },
  {
    name: 'Sigla',
    selector: (row) => row.sigla,
    sortable: true,
  },
  {
    name: 'Região',
    selector: (row) => row.regiao,
    sortable: true,
  },
  {
    name: 'Código de origem',
    selector: (row) => row.co_uf,
    sortable: true,
  },
  {
    name: 'Opções',
    selector: (row) => <OpcoesTrTable id={row.id} />,
    sortable: true,
  },
];
