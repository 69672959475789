import Card from 'components/card';
import SearchTable from './components/SearchTable';
import { InstituicaoEnsinoContextProvider } from './contexts/InstituicaoEnsinoContext';

const Index = () => {
  return (
    <Card extra={'w-full h-full bg-white mt-3'}>
      <InstituicaoEnsinoContextProvider>
        {/* Listagem de Instituição de Ensino */}
        <SearchTable></SearchTable>
      </InstituicaoEnsinoContextProvider>
    </Card>
  );
};

export default Index;
