import { NumericFormat } from 'react-number-format';

import OpcoesTrTableCidade from '../components/OpcoesTrTableCidade';

export const columns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row.nome,
    sortable: true,
  },
  {
    name: 'Estado',
    selector: (row) => row.estado?.sigla,
    sortable: true,
  },
  {
    name: 'Região',
    selector: (row) => row.estado?.regiao,
    sortable: true,
  },
  {
    name: 'Capital',
    selector: (row) => (row.capital ? 'Sim' : 'Não'),
  },
  {
    name: 'Fuso Horário',
    selector: (row) => row.fuso_horario,
  },
  {
    name: 'DDD',
    selector: (row) => row.ddd,
  },
  {
    name: 'População',
    selector: (row) => (
      <NumericFormat
        value={row.populacao}
        allowLeadingZeros
        thousandSeparator=","
      />
    ),
  },
  {
    name: 'Opções',
    selector: (row) => <OpcoesTrTableCidade id={row.id}></OpcoesTrTableCidade>,
    sortable: true,
  },
];
