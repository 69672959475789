import { useState } from 'react';
import { GrFormViewHide, GrFormView } from 'react-icons/gr';

const { Button } = require('@chakra-ui/button');
const { InputGroup, Input, InputRightElement } = require('@chakra-ui/input');

const PasswordInputField = ({ name, onChange, onBlur, value }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        type={show ? 'text' : 'password'}
        placeholder="Digite a senha."
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      <InputRightElement width="4.5rem">
        <Button h="2rem" onClick={handleClick} variant="ghost">
          {show ? <GrFormViewHide /> : <GrFormView />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInputField;
