import { Wrap, WrapItem } from '@chakra-ui/react';
import CourseButton from 'components/button/CourseButton';
import DeleteButton from 'components/button/DeleteButton';
import EditButton from 'components/button/EditButton';
import { useInstituicaoEnsino } from '../contexts/InstituicaoEnsinoContext';
import MapButton from 'components/button/MapButton';

const OpcoesTrTable = ({ id }) => {
  const {
    handleLoadUpdateInstituicaoEnsinoForm,
    handleLoadDeleteInstituicaoEnsinoModal,
    handleLoadCursosModal,
    handleLoadMapCidadeModal,
  } = useInstituicaoEnsino();

  const handleClickEditar = (event) => {
    handleLoadUpdateInstituicaoEnsinoForm(id);
  };

  const handleClickRemover = (event) => {
    handleLoadDeleteInstituicaoEnsinoModal(id);
  };

  const handleClickExibirMapa = (event) => {
    handleLoadMapCidadeModal(id);
  };

  const handleClickCursos = (event) => {
    handleLoadCursosModal(id);
  };

  return (
    <>
      <Wrap spacing={2}>
        <WrapItem>
          <DeleteButton handleClick={handleClickRemover} />
        </WrapItem>
        <WrapItem>
          <CourseButton handleClick={handleClickCursos} />
        </WrapItem>
        <WrapItem>
          <MapButton handleClick={handleClickExibirMapa} />
        </WrapItem>
        <WrapItem>
          <EditButton handleClick={handleClickEditar} />
        </WrapItem>
      </Wrap>
    </>
  );
};

export default OpcoesTrTable;
