import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useInstituicaoEnsino } from '../contexts/InstituicaoEnsinoContext';
import axiosClient from 'api/soutecapi';

const RemoverModal = () => {
  const { emptyInstituicoesEnsino, selectedIdInstituicaoEnsino, removerModal } =
    useInstituicaoEnsino();

  let [instituicaoEnsino, setInstituicaoEnsino] = useState(
    emptyInstituicoesEnsino
  );

  const fetchInstituicaoEnsino = async () => {
    try {
      let response = await axiosClient.get(
        `/instituicoesensino/${selectedIdInstituicaoEnsino}`
      );
      let data = await response.data.data;
      setInstituicaoEnsino({ ...data });
    } catch (error) {
      console.error('Error fetching todos:', error);
    }
  };

  useEffect(() => {
    // Cidade
    selectedIdInstituicaoEnsino !== 0
      ? fetchInstituicaoEnsino()
      : setInstituicaoEnsino(emptyInstituicoesEnsino);
  }, [selectedIdInstituicaoEnsino]);

  return (
    <>
      {/* Modal para confirmação de remoção. */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={removerModal.isOpen}
        onClose={removerModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Excluir</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            Confirma a remoção da Instituição de Ensino:{' '}
            {instituicaoEnsino.nome}?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3}>
              Remover
            </Button>
            <Button onClick={removerModal.onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RemoverModal;
