/* eslint-disable */

import { HiX } from 'react-icons/hi';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Links from './components/Links';
import Card from 'components/card';
import {
  renderThumb,
  renderTrack,
  renderView,
} from 'components/scrollbar/Scrollbar';
import routes from 'routes.js';

import logo from '../../assets/img/soutec/horizontal.png'
import { Center, Image } from '@chakra-ui/react';
const SidebarHorizon = ({ open, onClose, variant }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        variant === 'auth' ? 'xl:hidden' : 'xl:block'
      } ${open ? '' : '-translate-x-full'}`}
    >
      <Card
        extra={`w-[285px] ml-3 sm:mr-4 sm:my-4 h-[96.5vh] m-7 !rounded-[20px]`}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <span
                className="absolute right-4 top-4 block cursor-pointer xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>
              <Center>
                <Image src={logo} w="80%" h="80%" />
              </Center>
              <div className="mb-7 mt-[18px] h-px bg-gray-200 dark:bg-white/10" />
              {/* Nav item */}
              <ul className="ml-[10px] pt-1">
                <Links routes={routes} />
              </ul>
            </div>
          </div>
        </Scrollbars>
      </Card>
    </div>
  );
};

export default SidebarHorizon;
