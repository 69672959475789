import { Wrap, WrapItem } from '@chakra-ui/react';
import { useCidade } from '../contexts/CidadeContext';
import DeleteButton from 'components/button/DeleteButton';
import EditButton from 'components/button/EditButton';
import MapButton from 'components/button/MapButton';

const OpcoesTrTableCidade = ({ id }) => {
  const {
    handleLoadUpdateCidadeForm,
    handleLoadDeleteCidadeModal,
    handleLoadMapCidadeModal,
  } = useCidade();

  const handleClickEditar = (event) => {
    handleLoadUpdateCidadeForm(id);
  };

  const handleClickRemover = (event) => {
    handleLoadDeleteCidadeModal(id);
  };

  const handleClickExibirMapa = (event) => {
    handleLoadMapCidadeModal(id);
  };

  return (
    <Wrap spacing={2}>
      <WrapItem>
        <DeleteButton handleClick={handleClickRemover} />
      </WrapItem>
      <WrapItem>
        <MapButton handleClick={handleClickExibirMapa} />
      </WrapItem>
      <WrapItem>
        <EditButton handleClick={handleClickEditar} />
      </WrapItem>
    </Wrap>
  );
};

export default OpcoesTrTableCidade;
