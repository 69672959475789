import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

// Leaflet
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.js';

import App from './App';
import './index.css';
import { AuthContextProvider } from 'contexts/AuthContext';
import ReactErrorBoundary from 'erros/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ReactErrorBoundary>
    <ChakraProvider>
      <BrowserRouter>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </BrowserRouter>
    </ChakraProvider>
  </ReactErrorBoundary>
);
