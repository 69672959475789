import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useInstituicaoEnsino } from '../contexts/InstituicaoEnsinoContext';
import axiosClient from 'api/soutecapi';

const CursosModal = () => {
  const { selectedIdInstituicaoEnsino, cursosModal } = useInstituicaoEnsino();

  const [cursos, setCursos] = useState([]);

  const fetchInstituicaoEnsinoCursos = async () => {
    try {
      let response = await axiosClient.get(
        `/instituicoesensino/${selectedIdInstituicaoEnsino}/cursos`
      );
      let data = await response.data.data;
      setCursos([...data]);
    } catch (error) {
      console.error('Error fetching todos:', error);
    }
  };

  useEffect(() => {
    // Cidade
    selectedIdInstituicaoEnsino !== 0
      ? fetchInstituicaoEnsinoCursos()
      : setCursos([]);
  }, [selectedIdInstituicaoEnsino]);

  return (
    <>
      {/* Modal para confirmação de remoção. */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={cursosModal.isOpen}
        onClose={cursosModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cursos</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {cursos.map((curso) => (
              <div>
                <Card
                  direction={{ base: 'column', sm: 'row' }}
                  overflow="hidden"
                  variant="outline"
                  key={curso.id}
                >
                  <Image
                    objectFit="cover"
                    maxW={{ base: '100%', sm: '200px' }}
                    src={curso.img_url}
                    alt="Caffe Latte"
                  />

                  <Stack>
                    <CardBody>
                      <Heading size="md">{curso.nome}</Heading>
                    </CardBody>

                    <CardFooter>{/* botões */}</CardFooter>
                  </Stack>
                </Card>
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button onClick={cursosModal.onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CursosModal;
