import { Wrap, WrapItem } from '@chakra-ui/react';
import DeleteButton from 'components/button/DeleteButton';
import EditButton from 'components/button/EditButton';
import QuizzButton from 'components/button/QuizzButton';
import { useInteresse } from '../contexts/InteresseContext';

const OpcoesTrTableInteresse = ({ id }) => {
  const {
    handleLoadUpdateInteresseForm,
    handleLoadDeleteInteresseModal,
    handleLoadQuetoesModal,
  } = useInteresse();

  const handleClickQuestoes = (event) => {
    handleLoadQuetoesModal(id);
  };

  const handleClickEditar = (event) => {
    handleLoadUpdateInteresseForm(id);
  };

  const handleClickRemover = (event) => {
    handleLoadDeleteInteresseModal(id);
  };

  return (
    <>
      <Wrap spacing={2}>
        <WrapItem>
          <DeleteButton handleClick={handleClickRemover}>Remover</DeleteButton>
        </WrapItem>

        <WrapItem>
          <EditButton handleClick={handleClickEditar}>Editar</EditButton>
        </WrapItem>

        <WrapItem>
          <QuizzButton handleClick={handleClickQuestoes}>Questões</QuizzButton>
        </WrapItem>
      </Wrap>
    </>
  );
};

export default OpcoesTrTableInteresse;
