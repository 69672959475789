import { useDisclosure } from '@chakra-ui/hooks';
import { createContext, useState, useContext } from 'react';
import { useToastHook } from 'components/toast';
import { useNavigate } from 'react-router-dom';

import axiosClient from 'api/soutecapi';

export const InteresseContext = createContext({});

export function InteresseContextProvider({ children }) {
  const noSelectIdInteresse = 0;

  const navigate = useNavigate();

  const emptyInteresse = {
    id: 0,
    nome: '',
    sigla: '',
    descricao: '',
    questoes: [],
  };

  // Interesses
  const [interesses, setInteresses] = useState([]);

  // Interesse selecionado
  const [selectedInteresse, setSelectedInteresse] = useState(emptyInteresse);

  // Id Interesse selecionado.
  const [selectedIdInteresse, setSelectedIdInteresse] = useState(0);

  // Modal com formulário de edição.
  const editarModal = useDisclosure();

  // Modal com formulário de edição.
  const removerModal = useDisclosure();

  // Modal com formulário de edição.
  const quetoesModal = useDisclosure();

  const [toast, setToast] = useToastHook();

  const fetchInteresses = async () => {
    try {
      let response = await axiosClient.get('/interesses');
      let data = await response.data.data;
      setInteresses([...data]);
      return data;
    } catch (error) {
      console.log(error.response);
    }
  };

  const fetchInteresse = async () => {
    try {
      let response = await axiosClient.get(
        `/interesses/${selectedIdInteresse}`
      );
      let data = await response.data.data;
      setSelectedInteresse([...data]);
    } catch (error) {
      console.error(error.response);
    }
  };

  const updateInteresse = async (values) => {
    try {
      // Atualizar.
      let response = await axiosClient.put(
        `/interesses/${selectedIdInteresse}`,
        values
      );
      let data = await response.data.data;

      //Retornar item atualizado.
      return data;
    } catch (error) {
      console.error(error.response);
    }
  };

  const updateItensTableInteresses = (novoInteresse) => {
    const novosInteresses = interesses.map((itemInteresse) => {
      return itemInteresse.id === novoInteresse.id
        ? { ...novoInteresse }
        : itemInteresse;
    });

    setInteresses([...novosInteresses]);
  };

  const handleSubmitUpdateInteresseForm = async (values, { setSubmitting }) => {
    try {
      // Atualizar os dados no endpoint do servidor.
      let novoInteresse = await updateInteresse(values);

      // Atualizar listagem
      updateItensTableInteresses(novoInteresse);

      //Reiniciar formulário.
      setSelectedIdInteresse(noSelectIdInteresse);

      // Fechar modal.
      editarModal.onClose();

      // Mensagem de sucesso.
      setToast({
        title: 'Interesse',
        message: 'Atualizado com sucesso.',
        status: 'success',
      });
    } catch (error) {
      setToast({
        title: 'Interesse',
        message: 'Atualização não efetuada.',
        status: 'error',
      });
    }
  };

  const handleDeleteInteresse = async () => {
    try {
      //TODO: Remover item no endpoint do servidor.
      await axiosClient.delete(`/interesses/${selectedIdInteresse}`);

      // Remover item.
      const novasCidades = interesses.filter(
        (cidadeItem) => cidadeItem.id !== selectedIdInteresse
      );

      // Atualizar itens.
      setInteresses([...novasCidades]);

      // Reiniciar id do context.
      setSelectedIdInteresse(noSelectIdInteresse);

      // Fechar modal.
      removerModal.onClose();

      setToast({
        title: 'Interesse',
        message: 'Removido com sucesso',
        status: 'success',
      });
    } catch (error) {
      setToast({
        title: 'Interesse',
        message: 'Remoção não efetuada.',
        status: 'error',
      });
    }
  };

  const handleLoadUpdateInteresseForm = (id) => {
    // Item selecionado.
    setSelectedIdInteresse(id);

    // Exibir Modal do formulário.
    editarModal.onOpen();
  };

  const handleLoadDeleteInteresseModal = (id) => {
    // Item selecionado.
    setSelectedIdInteresse(id);

    // Exibir Modal de remoção.
    removerModal.onOpen();
  };

  const handleLoadQuetoesModal = (id) => {
    // Redireciomnamento para pãgina das questoes.
    navigate(`/admin/main/interesse/${id}/questao`);
  };

  return (
    <InteresseContext.Provider
      value={{
        emptyInteresse,
        interesses,
        setInteresses,
        selectedInteresse,
        setSelectedInteresse,
        selectedIdInteresse,
        setSelectedIdInteresse,
        editarModal,
        removerModal,
        quetoesModal,
        fetchInteresses,
        fetchInteresse,
        handleLoadUpdateInteresseForm,
        handleLoadDeleteInteresseModal,
        handleSubmitUpdateInteresseForm,
        handleDeleteInteresse,
        handleLoadQuetoesModal,
      }}
    >
      {children}
    </InteresseContext.Provider>
  );
}

export function useInteresse() {
  return useContext(InteresseContext);
}
