export const simpleLineChartData = [
    {
        competencia: 'mai/22',
        downloads: 360
    },
    {
        competencia: 'jun/22',
        downloads: 96000,
    },
    {
        competencia: 'jul/22',
        downloads: 10530,
    },
    {
        competencia: 'ago/22',
        downloads: 3290,
    },
    {
        competencia: 'set/22',
        downloads: 1620,
    },
    {
        competencia: 'out/22',
        downloads: 2630,
    },
    {
        competencia: 'nov/22',
        downloads: 1530,
    },
    {
        competencia: 'dez/22',
        downloads: 730,
    },
];

export const perfilInteressesData = [
    {
        interesse: 'Social',
        percentual: 35
    },
    {
        interesse: 'Organizacional',
        percentual: 38
    },
    {
        interesse: 'Intelectual-Científico',
        percentual: 16
    },
    {
        interesse: 'Empreendedor',
        percentual: 14
    },
    {
        interesse: 'Técnico-Operacional',
        percentual: 12
    },
    {
        interesse: 'Estético-Criativo',
        percentual: 6
    },
];

export const perfilValoresData = [
    {
        interesse: 'Equipe',
        percentual: 29
    },
    {
        interesse: 'Criatividade',
        percentual: 20
    },
    {
        interesse: 'Serviço',
        percentual: 16
    },
    {
        interesse: 'Autonomia',
        percentual: 16
    },
    {
        interesse: 'Supervisão',
        percentual: 6
    },
    {
        interesse: 'Aprendizagem',
        percentual: 5
    },
    {
        interesse: 'Estrutura',
        percentual: 4
    },
    {
        interesse: 'Poder',
        percentual: 3
    },
];

export const interessesPorGeneroData = [
    {
        interesse: 'Social',
        feminino: 75,
        masculino: 20,
        nao_declarado: 5
    },
    {
        interesse: 'Organizacional',
        feminino: 67,
        masculino: 28,
        nao_declarado: 5
    },
    {
        interesse: 'Intelectual-Científico',
        feminino: 50,
        masculino: 47,
        nao_declarado: 3
    },
    {
        interesse: 'Empreendedor',
        feminino: 52,
        masculino: 41,
        nao_declarado: 7
    },
    {
        interesse: 'Técnico-Operacional',
        feminino: 17,
        masculino: 79,
        nao_declarado: 4
    },
    {
        interesse: 'Estético-Criativo',
        feminino: 64,
        masculino: 31,
        nao_declarado: 5
    },
];

export const valoresPorGeneroData = [
    {
        valor: 'Equipe',
        feminino: 59,
        masculino: 35,
        nao_declarado: 6
    },
    {
        valor: 'Criatividade',
        feminino: 63,
        masculino: 32,
        nao_declarado: 5
    },
    {
        valor: 'Serviço',
        feminino: 74,
        masculino: 18,
        nao_declarado: 8
    },
    {
        valor: 'Autonomia',
        feminino: 62,
        masculino: 31,
        nao_declarado: 7 
    },
    {
        valor: 'Supervisão',
        feminino: 61,
        masculino: 35,
        nao_declarado: 4 
    },
    {
        valor: 'Aprendizagem',
        feminino: 59,
        masculino: 39,
        nao_declarado: 2 
    },
    {
        valor: 'Estrutura',
        feminino: 62,
        masculino: 36,
        nao_declarado: 2 
    },
    {
        valor: 'Poder',
        feminino: 53,
        masculino: 43,
        nao_declarado: 4  
    },
];