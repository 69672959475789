import React from 'react';
import { Flex, Stack, useDisclosure } from '@chakra-ui/react';

import { useCidade } from '../contexts/CidadeContext';
import AdicionarFormModalCidade from './AdicionarFormModalCidade';
import EditarFormModalCidade from './EditarFormModalCidade';
import RemoverModalCidade from './RemoverModalCidade';
import DataTablePaginationRemote from 'components/table/DataTablePaginationRemote';

import { columns } from '../variables/columnsData';
import AddButton from 'components/button/AddButton';
import ExibirMapaModalCidade from './ExibirMapaModalCidade';

const SearchTableCidades = () => {
  const { getData } = useCidade();

  // Modal com formulário de atualização.
  const adicionarModal = useDisclosure();

  const handleClickAdicionar = (event) => {
    adicionarModal.onOpen();
  };

  return (
    <>
      {/* Busca */}
      <Flex>
        <Stack className="mx-4 mt-4" direction="row" spacing={4}>
          <AddButton handleClick={handleClickAdicionar} />
          <AdicionarFormModalCidade adicionarModal={adicionarModal} />
        </Stack>
      </Flex>

      {/* Table */}
      <div className="mt-3 h-full w-full overflow-x-scroll xl:overflow-hidden">
        <DataTablePaginationRemote
          title={'Cidades'}
          columns={columns}
          getData={getData}
        />
      </div>

      {/* Editar */}
      <EditarFormModalCidade />

      {/* Remover */}
      <RemoverModalCidade />

      {/* Exibir Mapa */}
      <ExibirMapaModalCidade />
    </>
  );
};

export default SearchTableCidades;
