import { Button } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import React from 'react';
import { MdClass } from 'react-icons/md';

const CourseButton = ({ handleClick }) => {
  return (
    <Tooltip label="Cursos">
      <Button onClick={handleClick} colorScheme="gray">
        <MdClass />
      </Button>
    </Tooltip>
  );
};

export default CourseButton;
