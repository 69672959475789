import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  nome: Yup.string().required().label('Nome'),
  uf: Yup.string().max(2).required().label('Sigla'),
  regiao: Yup.string().required().label('Região'),
  codigo_uf: Yup.number().positive().integer().notRequired().label('Código UF'),
  latitude: Yup.number().required().label('Latitude'),
  longitude: Yup.number().required().label('Longitude'),
});

export default validationSchema;
