import { version } from 'variables/version';

const Footer = () => {
  return (
    <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
      <p className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
        <b>Nees</b> - Núcleo de Excelência em Tecnologias Sociais - Versão:{' '}
        {version.number}
      </p>
    </div>
  );
};

export default Footer;
