import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button } from '@chakra-ui/react';
import axiosClient from 'api/soutecapi';
import { useEffect, useState } from 'react';
import { useCidade } from '../contexts/CidadeContext';

const RemoverModalCidade = () => {
  const { emptyCidade, selectedIdCidade, removerModal, handleDeleteCidade } =
    useCidade();

  let [cidade, setCidade] = useState(emptyCidade);

  const fetchCidade = async () => {
    try {
      let response = await axiosClient.get(`/cidades/${selectedIdCidade}`);
      let data = await response.data.data;
      setCidade({ ...data });
    } catch (error) {
      console.error('Error fetching todos:', error);
    }
  };

  useEffect(() => {
    // Cidade
    selectedIdCidade !== 0 ? fetchCidade() : setCidade(emptyCidade);
  }, [selectedIdCidade]);

  const handleClick = (event) => {
    handleDeleteCidade();
  };
  return (
    <>
      {/* Modal para confirmação de remoção. */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={removerModal.isOpen}
        onClose={removerModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Excluir</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            Confirma a remoção da cidade: {cidade.nome}?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleClick}>
              Remover
            </Button>
            <Button onClick={removerModal.onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RemoverModalCidade;
