import { useEffect, useState } from "react";
import { useInstituicaoEnsino } from "../contexts/InstituicaoEnsinoContext";
import { useToastHook } from "components/toast";
import axiosClient from 'api/soutecapi';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import MakerMap from "components/map/MakerMap";
import { CloseButton } from "@chakra-ui/react";

const ExibirMapaModalInstituicaoEnsino = () => {
  const { emptyInstituicoesEnsino, selectedIdInstituicaoEnsino, exibirMapaModal } = useInstituicaoEnsino(); 

  const [toast, setToast] = useToastHook();


  let [instituicaoEnsino, setInstituicaoEnsino] = useState(emptyInstituicoesEnsino);
  let [position, setPosition] = useState([0, 0]);

  const fetchCidade = async () => {
    try {
      let response = await axiosClient.get(`/instituicoesensino/${selectedIdInstituicaoEnsino}`);
      let data = await response.data.data;
      setInstituicaoEnsino({ ...data });
    } catch (error) {
      setToast({
        title: 'Mapa',
        message: 'Não foi possível encontrar localização.',
        status: 'error',
      });
      exibirMapaModal.onClose();
    }
  };

  useEffect(() => {
    // Instituição de Ensino
    selectedIdInstituicaoEnsino !== 0 ? fetchCidade() : setInstituicaoEnsino(emptyInstituicoesEnsino);
  }, [selectedIdInstituicaoEnsino]);

  useEffect(() => setPosition([instituicaoEnsino.latitude, instituicaoEnsino.longitude]), [instituicaoEnsino]);

  const handleClickClose = (event) => {
    exibirMapaModal.onClose();
  };
  return (
    <>
      {/* Modal para confirmação de remoção. */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={exibirMapaModal.isOpen}
        onClose={exibirMapaModal.onClose}
        size={'xl'}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="70rem">
          <ModalHeader>Mapa</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <MakerMap position={position} />
          </ModalBody>

          <ModalFooter>
            <CloseButton handleClick={handleClickClose} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ExibirMapaModalInstituicaoEnsino