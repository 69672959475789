import React, { useEffect, useState } from 'react';
import Card from 'components/card';
import SearchTable from './components/SearchTable';
import { columnsData } from './variables/columnsData';
import axiosClient from 'api/soutecapi';
import { CursoContextProvider } from './contexts/CursoContext';

const Index = () => {
  // const [tableData, setTableData] = useState([]);

  // useEffect(() => {
  //   axiosClient.get('/cursos').then((response) => {
  //     let eixostecnologicos = response.data.data;
  //     setTableData([...eixostecnologicos]);
  //   });
  // }, []);

  return (
    <Card extra={'w-full h-full bg-white mt-3'}>
      {/* Listagem de Eixo Tecnológico */}
      <CursoContextProvider>
        <SearchTable
          // tableData={tableData}
          // columnsData={columnsData}
        ></SearchTable>
      </CursoContextProvider>
    </Card>
  );
};

export default Index;
