import { CloseButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import MakerMap from "components/map/MakerMap";
import { useToastHook } from "components/toast";
import { useEffect, useState } from "react";
import { useEstado } from "../contexts/EstadoContext";

const ExibirMapaModal = ({ id, mapaModal }) => {
  const { emptyEstado, fetchEstadoId } = useEstado(); 

  const [toast, setToast] = useToastHook();

  let [estado, setEstado] = useState(emptyEstado);
  let [position, setPosition] = useState([0, 0]);

  const fetchEstado = async () => {
    try{
      const response = await fetchEstadoId(id)
      setEstado({ ...response })
    } catch (error) {
      setToast({
        title: 'Mapa',
        message: 'Não foi possível encontrar localização.',
        status: 'error',
      });
      mapaModal.onClose();
   }
  }

  useEffect(() => {
    // Estado
    id !== 0 ? fetchEstado() : setEstado(emptyEstado);
  }, [id]);

  useEffect(() => setPosition([estado.latitude, estado.longitude]), [estado]);

  const handleClickClose = (event) => {
    mapaModal.onClose();
  };

  return (
    <>
      {/* Modal para confirmação de remoção. */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={mapaModal.isOpen}
        onClose={mapaModal.onClose}
        size={'xl'}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="70rem">
          <ModalHeader>Mapa</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <MakerMap position={position} zoomLevel={6}/>
          </ModalBody>

          <ModalFooter>
            <CloseButton handleClick={handleClickClose} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ExibirMapaModal;
