import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import RequireAuth from 'router/RequiredAuth';
import globalRouter from 'router/GlobalRouter';
import 'assets/css/Plugins.css';

const App = () => {
  // Navegação para não components.
  const navigate = useNavigate();

  useEffect(() => {
    globalRouter.navigate = navigate;
  }, []);

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={
          <RequireAuth>
            <AdminLayout />
          </RequireAuth>
        }
      />
      <Route path="/" element={<Navigate to="/auth" replace />} />
    </Routes>
  );
};

export default App;
