import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';

import { useCidade } from '../contexts/CidadeContext';
import axiosClient from 'api/soutecapi';
import CloseButton from 'components/button/CloseButton';
import { useToastHook } from 'components/toast';
import MakerMap from 'components/map/MakerMap';

const ExibirMapaModalCidade = () => {
  const { emptyCidade, selectedIdCidade, exibirMapaModal } = useCidade(); 

  const [toast, setToast] = useToastHook();

  let [cidade, setCidade] = useState(emptyCidade);
  let [position, setPosition] = useState([0, 0]);

  const fetchCidade = async () => {
    try {
      let response = await axiosClient.get(`/cidades/${selectedIdCidade}`);
      let data = await response.data.data;
      setCidade({ ...data });
    } catch (error) {
      setToast({
        title: 'Mapa',
        message: 'Não foi possível encontrar localização.',
        status: 'error',
      });
      exibirMapaModal.onClose();
    }
  };

  useEffect(() => {
    // Cidade
    selectedIdCidade !== 0 ? fetchCidade() : setCidade(emptyCidade);
  }, [selectedIdCidade]);

  useEffect(() => setPosition([cidade.latitude, cidade.longitude]), [cidade]);

  const handleClickClose = (event) => {
    exibirMapaModal.onClose();
  };

  return (
    <>
      {/* Modal para confirmação de remoção. */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={exibirMapaModal.isOpen}
        onClose={exibirMapaModal.onClose}
        size={'xl'}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="70rem">
          <ModalHeader>Mapa</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <MakerMap position={position} />
          </ModalBody>

          <ModalFooter>
            <CloseButton handleClick={handleClickClose} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExibirMapaModalCidade;
