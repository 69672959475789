import { SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import DataTablePaginationRemote from 'components/table/DataTablePaginationRemote';
import { MdAdd } from 'react-icons/md';
import { useInstituicaoEnsino } from '../contexts/InstituicaoEnsinoContext';
import { columnsData } from '../variables/columnsData';
import AdicionarFormModal from './AdicionarFormModal';
import CursosModal from './CursosModal';
import EditarFormModal from './EditarFormModal';
import RemoverModal from './RemoverModal';
import ExibirMapaModalInstituicaoEnsino from './ExibirMapaModalInstituicaoEnsino';
import AddButton from 'components/button/AddButton';

const SearchTable = () => {
  const { getDataInstituicoesEnsinoPagination } = useInstituicaoEnsino();

  const adicionarModal = useDisclosure();

  const handleClickAdicionar = (event) => {
    adicionarModal.onOpen();
  };

  return (
    <div className="h-full w-full">
      {/* Busca */}
      <Flex>
        <Stack className="mx-4 mt-4" direction="row" spacing={4}>
          <AddButton handleClick={handleClickAdicionar} />
          <AdicionarFormModal adicionarModal={adicionarModal} />
        </Stack>
      </Flex>

      {/* table */}
      <div className="mt-11 h-full w-full overflow-x-scroll xl:overflow-hidden">
        <DataTablePaginationRemote
          title={'Instituições de Ensino'}
          columns={columnsData}
          getData={getDataInstituicoesEnsinoPagination}
        />
      </div>
      {/* Editar */}
      <EditarFormModal />
      {/* Remover */}
      <RemoverModal />
      {/* Cursos */}
      <CursosModal />
      {/* Exibir Mapa */}
      <ExibirMapaModalInstituicaoEnsino />
    </div>
  );
};

export default SearchTable;
