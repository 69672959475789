import { Button } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import React from 'react';
import { MdQuiz } from 'react-icons/md';

const QuizzButton = ({ handleClick }) => {
  return (
    <Tooltip label="Questões">
      <Button onClick={handleClick} colorScheme="gray">
        <MdQuiz />
      </Button>
    </Tooltip>
  );
};

export default QuizzButton;
