import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { HttpStatusCode } from 'axios';

import soutecapi from 'api/soutecapi';
import { useToastHook } from 'components/toast';

export const AuthContext = createContext({});

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);

  const [toast, setToast] = useToastHook();

  useEffect(() => {
    const loadingStorageData = async () => {
      const storageToken = localStorage.getItem('soutec@auth:token');
      if (storageToken) {
        setUser(storageToken);
      }
    };
    loadingStorageData();
  }, []);

  const handleSignIn = async ({ email, password }, { resetForm }) => {
    try {
      // Recurso do Login
      const response = await soutecapi.post('/login', {
        email,
        password,
      });
      if (response.status === HttpStatusCode.Ok) {
        let token = response.data.authorization.token;
        setUser(token);
        localStorage.setItem('soutec@auth:token', token);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === HttpStatusCode.Unauthorized
      ) {
        setToast({
          title: 'Login',
          message: 'Usuário e/ou senha não autorizados.',
          status: 'error',
        });
      }
    } finally {
      resetForm();
    }
  };

  const isEmpty = (str) => !str?.length;

  const handleSignOut = async () => {
    //TODO: Invalidar chave na API.
    //const response = await soutecapi.post('/logout');

    // if (response && !isEmpty(user)) {
    if (!isEmpty(user)) {
      // remover o token
      localStorage.clear();
      setUser(null);
      // Redirecionar para a página de login
      return <Navigate to="/auth/sign-in" />;
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, signed: !!user, handleSignIn, handleSignOut }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
