import { Box, Flex, Stack } from '@chakra-ui/react';

import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import React from 'react';

//TODO: Verificar condições com rota definida pelo nome.
function SidebarContent(props) {
  const routes = props.routes.filter(
    (route) =>
      route.name !== 'Cursos / Detalhes' &&
      route.name !== 'Eixo Tecnológico / Detalhes'
  );

  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      px="16px"
      borderRadius="30px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="20px" pe={{ md: '16px', '2xl': '1px', '': '' }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Box mt="60px" mb="40px" borderRadius="30px"></Box>
    </Flex>
  );
}

export default SidebarContent;
