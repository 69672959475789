import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { simpleLineChartData } from '../../variables/soutecCharts'
import Card from 'components/card';
// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,


//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

export default function Evolution() {
    return (
        <Card extra={"h-[381px] pb-8 px-6 pt-6"}>
            <div className="flex justify-between px-3 pt-1">
                <div className="flex items-center">
                    <div className="flex flex-col">
                        <p className="text-[22px] font-bold text-navy-700 dark:text-white pb-4">
                            {" "}
                            Evolução dos Downloads
                        </p>
                    </div>
                </div>
            </div>

            {/* Charts */}
            <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
                <div className="h-full w-full">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width="100%"
                            height={300}
                            data={simpleLineChartData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="2 2" />
                            <XAxis dataKey="competencia" tick={{stroke: '#A3AED0', strokeWidth: 0.2}} fontSize={12} />
                            <YAxis tick={{stroke: '#A3AED0', strokeWidth: 0.2}} fontSize={12} />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Line type="monotone" dataKey="downloads" stroke="#8884d8" activeDot={{ r: 1 }} strokeWidth={2} />
                            {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                        </LineChart>
                    </ResponsiveContainer>
                    {/* <LineChart
            chartData={lineChartDataOverallRevenue}
            chartOptions={lineChartOptionsOverallRevenue}
          /> */}
                </div>
            </div>
        </Card>

    );
}
