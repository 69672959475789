import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import axiosClient from 'api/soutecapi';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import validationSchema from '../variables/Validation';

const EditarFormModal = ({ id, editarModal }) => {
  const initialRef = React.useRef(null);

  let [eixoTecnologico, setEixoTecnologico] = useState([]);

  async function fetchEixoTecnologico() {
    let response = await axiosClient.get(`/eixostecnologicos/${id}`);
    let eixoTecnologico = response.data.data;
    setEixoTecnologico({ ...eixoTecnologico });
  }

  useEffect(() => {
    if(editarModal.isOpen){
      fetchEixoTecnologico();
    }
  }, [editarModal.isOpen]);

  if (eixoTecnologico.length === 0) {
    return null; // Local para botar o Spinner
  }

  return (
    <>
      {/* Modal com o formulário de Atualização */}
      <Modal
        initialFocusRef={initialRef}
        isOpen={editarModal.isOpen}
        onClose={editarModal.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={eixoTecnologico}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));

                console.log(values);

                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <ModalBody pb={6}>
                  {/* Nome */}
                  <FormControl>
                    <FormLabel htmlFor="nome">Nome</FormLabel>
                    <Input
                      type="text"
                      name="nome"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome}
                      ref={initialRef}
                    />
                    <ErrorMessage name="nome" component="div" />
                  </FormControl>

                  {/* Descrição */}
                  <FormControl>
                    <FormLabel htmlFor="descricao">Descrição</FormLabel>
                    <Textarea
                      type="text"
                      name="descricao"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.descricao}
                    />
                    <ErrorMessage name="descricao" component="div" />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    colorScheme="blue"
                    mr={3}
                  >
                    Salvar
                  </Button>
                  <Button onClick={editarModal.onClose}>Cancelar</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarFormModal;
