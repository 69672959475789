import OpcoesTrTableQuestao from '../components/OpcoesTrTableQuestao';

export const columnsData = [
  {
    name: 'Descrição',
    selector: (row) => row.descricao,
    sortable: true,
  },
  {
    name: 'Opções',
    selector: (row) => <OpcoesTrTableQuestao id={row.id}/>,
    sortable: true,
  },
];
