import {
  Wrap,
  WrapItem,
  useDisclosure
} from '@chakra-ui/react';
import DeleteButton from 'components/button/DeleteButton';
import EditButton from 'components/button/EditButton';
import EditarFormModal from './EditarFormModal';
import RemoverModal from './RemoverModal';

const OpcoesTrTable = (props) => {
  const { id } = props;

  // Modal com formulário de atualização.
  const editarModal = useDisclosure();

  // Modal confirmação de remoção.
  const removerModal = useDisclosure();

  const handleClickEditar = (event) => {
    editarModal.onOpen();
  };

  const handleClickRemover = (event) => {
    removerModal.onOpen();
  };

  return (
    <>
      <Wrap spacing={2}>
        <WrapItem>
          <DeleteButton handleClick={handleClickRemover}>
            Remover
          </DeleteButton>
          {/* Remover */}
          <RemoverModal id={id} removerModal={removerModal} />
        </WrapItem>
        <WrapItem>
          <EditButton handleClick={handleClickEditar}>
            Editar
          </EditButton>
          {/* Adicionar */}
          <EditarFormModal id={id} editarModal={editarModal} />
        </WrapItem>
      </Wrap>
    </>
  );
};

export default OpcoesTrTable;
