import { Button } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import React from 'react';
import { MdClose } from 'react-icons/md';

const CloseButton = ({ handleClick }) => {
  return (
    <Tooltip label="Fechar">
      <Button
        onClick={handleClick}
        variant="solid"
        colorScheme="gray"
        leftIcon={<MdClose />}
      >
        Fechar
      </Button>
    </Tooltip>
  );
};

export default CloseButton;
