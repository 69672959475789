import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { perfilValoresData } from '../../variables/soutecCharts'
import Card from 'components/card';

export default function PerfilValores() {
    return (
        <Card extra={"h-[381px] pb-8 px-6 pt-6"}>
            <div className="flex justify-between px-3 pt-1">
                <div className="flex items-center">
                    <div className="flex flex-col">
                        <p className="text-[22px] font-bold text-navy-700 dark:text-white pb-4">
                            {" "}
                            Perfil de Valores
                        </p>
                    </div>
                </div>
            </div>

            {/* Charts */}
            <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
                <div className="h-full w-full">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={`100%`} height={300} margin={{ left: 50 }}
                            data={perfilValoresData} layout="vertical">
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis fontSize={10} type="number" />
                            <YAxis dataKey="interesse" type="category" fontSize={12} />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Bar dataKey="percentual" fill="#FFE48B" opacity={0.8} />
                        </BarChart>
                    </ResponsiveContainer>
                    {/* <LineChart
            chartData={lineChartDataOverallRevenue}
            chartOptions={lineChartOptionsOverallRevenue}
          /> */}
                </div>
            </div>
        </Card>

    );
}
