import OpcoesTrTable from '../components/OpcoesTrTable';

export const columnsData = [
  {
    name: 'Eixo Tecnológico',
    selector: (row) => row.nome,
    sortable: true,
  },
  {
    name: 'Descricão',
    selector: (row) => row.descricao,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Opções',
    selector: (row) => <OpcoesTrTable id={row.id} />,
    sortable: true,
  },
];
