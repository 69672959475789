import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  nome: Yup.string().required(),
  latitude: Yup.number().required(),
  longitude: Yup.number().required(),
  fuso_horario: Yup.string().required(),
  ddd: Yup.number().positive().integer().required(),
  codigo_ibge: Yup.number().positive().integer().required(),
  siafi_id: Yup.number().positive().integer().required(),
});

export default validationSchema;
