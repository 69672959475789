import { Button } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import React from 'react';
import { MdEdit } from 'react-icons/md';

const EditButton = ({ handleClick, isLoadingClick}) => {
  return (
    <Tooltip label="Editar">
      <Button onClick={handleClick} isLoadingClick={isLoadingClick} colorScheme="gray">
        <MdEdit />
      </Button>
    </Tooltip>
  );
};

export default EditButton;
