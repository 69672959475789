import Card from 'components/card';
import SearchTableInteresses from './components/SearchTableInteresses';
import { InteresseContextProvider } from './contexts/InteresseContext';

const Index = () => {
  return (
    <Card extra={'w-full h-full bg-white mt-3'}>
      <InteresseContextProvider>
        <SearchTableInteresses></SearchTableInteresses>
      </InteresseContextProvider>
    </Card>
  );
};

export default Index;
