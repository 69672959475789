import { BarLoader } from 'react-spinners';

const Progress = () => {
  return (
    <>
      <span className="mx-3">Carregando</span>
      <BarLoader color="#422AFB" />
    </>
  );
};

export default Progress;
