// import * as dfd from "danfojs"

// //import specific methods/classes
// import { Series } from "danfojs"

// export default function TestDanfoJS() {
//     const s = new dfd.Series([1, 3, 5, undefined, 6, 8]);
//     // s.print();

//     return (
//         <div>
//             { s.$data.map( (e, i) => <span key={i}>{e}</span>) }
//         </div>
//     );
// }

import React from "react";
// import Balance from "./components/Balance";
// import DailyTraffic from "./components/DailyTraffic";
// import MostVisited from "./components/MostVisited";
// import OverallRevenue from "./components/OverallRevenue";
// import ProfitEstimation from "./components/ProfitEstimation";
// import ProjectStatus from "./components/ProjectStatus";
// import YourCard from "./components/YourCard";
// import YourTransfers from "./components/YourTransfers";

// import { tableColumnsMostVisited } from "./variables/tableColumnsMostVisited";
// import tableDataMostVisited from "./variables/tableDataMostVisited";
import Evolution from "./components/soutec/Evolution";
import PerfilInteresses from "./components/soutec/PerfilInteresses";
import PerfilValores from "./components/soutec/PerfilValores";
import SBChartInteressesPorGenero from "./components/soutec/SBChartInteressesPorGenero";
import SBChartValoresPorGenero from "./components/soutec/SBChartValoresPorGenero";

const LibJSDashboard = () => {
  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        {/* left side */}
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          {/* overall & Balance */}
          <div className="mb-5 grid grid-cols-2 gap-5">
            <div className="col-span-2 h-full w-full rounded-xl 3xl:col-span-4">
              {/* <OverallRevenue /> */}
              <Evolution />
            </div>
            {/* <div className="col-span-6 w-full 3xl:col-span-2">
              <Balance />
            </div> */}
          </div>
          {/* Daily Traffic and.. */}
          <div className="mt-5 grid w-full grid-cols-4 gap-5">
            <div className="col-span-4 md:col-span-3 3xl:col-span-2">
              <PerfilInteresses />
            </div>
            {/* <div className="col-span-6 md:col-span-3 3xl:col-span-2">
              <ProjectStatus />
            </div> */}
            <div className="col-span-4 3xl:col-span-2">
              <PerfilValores />
            </div>
          </div>
          <div className="mt-5 grid w-full grid-cols-4 gap-5">
            <div className="col-span-4 md:col-span-3 3xl:col-span-2">
              <SBChartInteressesPorGenero />
            </div>
            {/* <div className="col-span-6 md:col-span-3 3xl:col-span-2">
              <ProjectStatus />
            </div> */}
            <div className="col-span-4 3xl:col-span-2">
              <SBChartValoresPorGenero />
            </div>
          </div>
          {/* Your Transfers & tables */}
          {/* <div className="mt-5 grid w-full grid-cols-6 gap-5">
            <div className="col-span-6 3xl:col-span-2">
              <YourTransfers />
            </div>
            <div className="col-span-6 3xl:col-span-4">
              <MostVisited
                tableData={tableDataMostVisited}
                columnsData={tableColumnsMostVisited}
              />
            </div>
          </div> */}
        </div>
      </div>

      {/* line */}
      <div className="flex w-0 bg-gray-200 dark:bg-navy-700 xl:w-px" />

      {/* right section */}
      {/* <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
        <YourCard />
      </div> */}
    </div>
  );
};

export default LibJSDashboard;
