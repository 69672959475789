import {
  Wrap,
  WrapItem,
  useDisclosure
} from '@chakra-ui/react';
import DeleteButton from 'components/button/DeleteButton';
import EditButton from 'components/button/EditButton';
import { memo } from 'react';
import EditarFormModal from './EditarFormModal';
import RemoverModal from './RemoverModal';
import MapButton from 'components/button/MapButton';
import ExibirMapaModal from './ExibirMapaModal';

const OpcoesTrTable = memo((props) => {
  const { id } = props;
  
  // Modal com formulário de atualização.
  const editarModal = useDisclosure();

  // Modal confirmação de remoção.
  const removerModal = useDisclosure();

  // Modal confirmação de remoção.
  const mapaModal = useDisclosure();

  const handleClickEditar = async (event) => {
    editarModal.onOpen();
  };

  const handleClickRemover = (event) => {
    removerModal.onOpen();
  };

  const handleClickMapa = (event) => {
    mapaModal.onOpen();
  };

  return (
    <>
      <Wrap spacing={2}>
        <WrapItem>
          <DeleteButton handleClick={handleClickRemover} />
          {/* Remover */}
          <RemoverModal id={id} removerModal={removerModal} />
        </WrapItem>

        <WrapItem>
          <MapButton handleClick={handleClickMapa} />
          {/* Mapa */}
          <ExibirMapaModal id={id} mapaModal={mapaModal} />
        </WrapItem>
        
        <WrapItem>
          <EditButton
            handleClick={handleClickEditar}
          />
          {/* Editar */}
          <EditarFormModal id={id} editarModal={editarModal}/>
        </WrapItem>
      </Wrap>
    </>
  );
});

export default OpcoesTrTable;
