import { createContext, useContext, useState } from "react";
import axiosClient  from "api/soutecapi";

export const CursoContext = createContext({})

export function CursoContextProvider({ children }) {

  const [cursos, setCursos] = useState([])

  const fetchCursos = async () => {
    try {
      let response = await axiosClient.get('/cursos')
      let data = await response.data.data
      setCursos([...data])
    } catch (error) {
      console.log(error)
    }
  }

  const getData = async (page = 1, perPage = 10) => {
    try {
      let response = await axiosClient.get(
        `/cursos/?page=${page}&limit=${perPage}`
      );
      let data = await response.data;
      return data;
    } catch (error) {
      console.log(error.response);
    }
  };

  const fetchEixoTecnologicoCurso = async (id) => {
    let response = await axiosClient.get(`/cursos/${id}`);
    console.log(response.data)
    let curso = response.data.data;
    setCursos({ ...curso });
    return curso
  }

  return (
    <CursoContext.Provider value={{
      cursos,
      setCursos,
      fetchCursos,
      getData,
      fetchEixoTecnologicoCurso
    }}>
      {children}
    </CursoContext.Provider>
  )
}

export function useCurso(){
  return useContext(CursoContext)
}