import { useDisclosure } from '@chakra-ui/hooks';
import { createContext, useState, useContext } from 'react';
import { useToastHook } from 'components/toast';
import { useNavigate } from 'react-router-dom';

import axiosClient from 'api/soutecapi';

export const QuestaoContext = createContext({});

export function QuestaoContextProvider({ children }) {
  const noSelectIdQuestao = 0;

  const navigate = useNavigate();

  const emptyQuestao = {
    id: 0,
    codigo: '',
    rotulo: '',
    descricao: '',
  };

  // Interesses
  const [questoes, setQuestoes] = useState([]);

  // Interesse selecionado
  const [selectedQuestao, setSelectedQuestao] = useState(emptyQuestao);

  // Id Interesse selecionado.
  const [selectedIdQuestao, setSelectedIdQuestao] = useState(0);

  // Modal com formulário de edição.
  const editarModal = useDisclosure();

  // Modal com formulário de edição.
  const removerModal = useDisclosure();

  const [toast, setToast] = useToastHook();

  const fetchQuestoes = async (idInteresse) => {
    try {
      let response = await axiosClient.get(
        `/interesses/${idInteresse}/questoes`
      );
      let data = await response.data.data;
      // console.log(data);
      setQuestoes([...data]);
      return data
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleLoadDeleteQuestaoModal = (id) => {
    // Item selecionado.
    setSelectedIdQuestao(id);

    // Exibir Modal de remoção.
    removerModal.onOpen();
  };

  const handleLoadUpdateQuestaoForm = (id) => {
    // Item selecionado.
    setSelectedIdQuestao(id);

    // Exibir Modal do formulário.
    editarModal.onOpen();
  };
  return (
    <QuestaoContext.Provider
      value={{
        emptyQuestao,
        interesses: questoes,
        setInteresses: setQuestoes,
        selectedInteresse: selectedQuestao,
        setSelectedInteresse: setSelectedQuestao,
        selectedIdInteresse: selectedIdQuestao,
        setSelectedIdInteresse: setSelectedIdQuestao,
        editarModal,
        removerModal,
        fetchQuestoes,
        handleLoadDeleteQuestaoModal,
        handleLoadUpdateQuestaoForm,
      }}
    >
      {children}
    </QuestaoContext.Provider>
  );
}

export function useQuestao() {
  return useContext(QuestaoContext);
}
