import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import Progress from 'components/progress';

const DataTablePaginationRemote = ({
  title = '',
  columns,
  getData,
  defaultTotalRows = 0,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchData(page); // fetch page 1 of users
  }, []);

  const fetchData = async (page) => {
    setLoading(true);
    const responseData = await getData(page, perPage);
    console.log(responseData);
    setData(responseData.data);
    setTotalRows(getTotalRows(responseData.meta));
    setPage(page);
    setLoading(false);
  };

  function getTotalRows(meta) {
    return meta ? meta.total : defaultTotalRows;
  }

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const responseData = await getData(page, newPerPage);
    setData(responseData.data);
    setTotalRows(getTotalRows(responseData.meta));
    setPage(page);
    setPerPage(newPerPage);
    setLoading(false);
  };

  return (
    <DataTable
      title={title}
      columns={columns}
      data={data}
      progressPending={loading}
      progressComponent={<Progress />}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

export default DataTablePaginationRemote;
