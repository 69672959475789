import React from 'react';

// Chakra imports
import { Flex, Image } from '@chakra-ui/react';

// Custom components
import logo from 'assets/img/soutec/horizontal.png';
export function SidebarBrand() {
  //   Chakra color mode

  return (
    <Flex justify="center">
      <Image src={logo} w="150px" h="45px" />
    </Flex>
  );
}

export default SidebarBrand;
