import { Button } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import React from 'react';
import { MdDelete } from 'react-icons/md';

const DeleteButton = ({ handleClick }) => {
  return (
    <Tooltip label="Remover">
      <Button onClick={handleClick} colorScheme="red">
        <MdDelete />
      </Button>
    </Tooltip>
  );
};

export default DeleteButton;
