import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Portal } from '@chakra-ui/portal';
import { useDisclosure } from '@chakra-ui/hooks';
import { Box } from '@chakra-ui/react';
import { pathToRegexp } from 'path-to-regexp';

import Sidebar from 'components/sidebar';
import Footer from 'components/footer/Footer';
import NavbarAdmin from 'components/navbar/NavbarAdmin';
import routes from 'routes.js';

export default function Admin(props) {
  const { ...rest } = props;

  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [fixed, setFixed] = useState(false);
  const [currentRoute, setCurrentRoute] = useState('');
  const { onOpen } = useDisclosure();

  useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  useEffect(() => {
    console.log('mudou o location: ' + location.pathname);
    getActiveRoute(routes);
    // eslint-disable-next-line
  }, [location.pathname]);
  // functions for changing the states from components

  const getActiveRoute = (routes) => {
    let activeRoute = 'Título padrão';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        // Verifica se a rota tem paramentros
        if (routes[i].path.includes(':')) {
          // Converte a rota para um regex
          const routeRegex = pathToRegexp(routes[i].layout + routes[i].path);
          // Verifica se a rota atual bate com a rota do regex
          if (routeRegex.test(window.location.pathname)) {
            console.log('passou');
            console.log('window.location.href: ' + window.location.href);
            console.log('route: ' + routes[i].layout + routes[i].path);
            setCurrentRoute(routes[i].name);
          }
        } else {
          // Se não tiver parametros segue normal
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path) !==
            -1
          ) {
            console.log('window.location.href: ' + window.location.href);
            console.log('route: ' + routes[i].layout + routes[i].path);
            setCurrentRoute(routes[i].name);
          }
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === '/admin') {
        return <Route path={route.path} element={route.component} key={key} />;
      }
      if (route.collapse) {
        return getRoutes(route.items);
      }
      if (route.category) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };

  return (
    <div className="flex h-full w-full">
      {/* Sidebar - Menu */}
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full font-dm dark:bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-2.5 flex-none transition-all dark:bg-navy-900 md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div>
            <Portal>
              {/* Navbar */}
              <Box>
                <NavbarAdmin
                  onOpen={onOpen}
                  logoText={'Soutec'}
                  brandText={currentRoute}
                  secondary={getActiveNavbar(routes)}
                  message={getActiveNavbarText(routes)}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>
            {/* Main - Conteúdo principal */}
            <div className="mx-auto min-h-screen p-2 !pt-[100px] md:p-2">
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/dashboards/libJS" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
