import { useParams } from 'react-router-dom';

import SearchTableQuestoes from './components/SearchTableQuestoes';
import { QuestaoContextProvider } from './contexts/QuestaoContext';

const Index = () => {
  const { id } = useParams();
  
  return (
    <QuestaoContextProvider>
      <SearchTableQuestoes idQuestao={id}/>
    </QuestaoContextProvider>
  );
};

export default Index;
