import { DataTableClient } from "components/table/DataTableClient";
import { useQuestao } from "../contexts/QuestaoContext";
import { columnsData } from "../variables/columnsData";
import { Button, Flex, Input, InputGroup, InputLeftElement, Stack, useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { MdAdd } from "react-icons/md";
import AdicionarFormModalInteresse from "../../interesse/components/AdicionarFormModalInteresse";
import { EditarFormModalQuestao } from "./EditarFormModalQuestao";
import { RemoverModalQuestao } from "./RemoverModalQuestao";

const SearchTableQuestoes = ({ idQuestao }) => {
  const { fetchQuestoes } = useQuestao();

  const [filterText, setFilterText] = useState('');

  // const adicionarModal = useDisclosure();

  // const handleClickAdicionar = (event) => {
  //   adicionarModal.onOpen();
  // };


  return (
    <>
      {/* Busca */}
      <Flex>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            onChange={(e) => setFilterText(e.target.value)}
            type="text"
            placeholder="Buscar"
          />
        </InputGroup>

        <Stack className="mx-4" direction="row" spacing={4}>
          <Button
            // onClick={handleClickAdicionar}
            leftIcon={<MdAdd />}
            variant="solid"
            colorScheme="blue"
          >
            Adicionar
          </Button>
          {/* <AdicionarFormModalInteresse adicionarModal={adicionarModal} /> */}
        </Stack>
      </Flex>

      <div className="mt-11 h-full w-full overflow-x-scroll xl:overflow-hidden">
        <DataTableClient 
          title={'Questões'}
          fetchEntity={() => fetchQuestoes(idQuestao)}
          columns={columnsData}
          filterSearchText={filterText}
        />
      </div>
      {/* Editar */}
      {/* <EditarFormModalQuestao /> */}
      
      {/* Remover */}
      {/* <RemoverModalQuestao /> */}
    </>
  )
}

export default SearchTableQuestoes;
