import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button } from '@chakra-ui/react';
import axiosClient from 'api/soutecapi';
import { useEffect, useState } from 'react';
import { useInteresse } from '../contexts/InteresseContext';

const RemoverModalInteresse = () => {
  const {
    emptyInteresse,
    selectedIdInteresse,
    removerModal,
    handleDeleteInteresse,
  } = useInteresse();

  let [interesse, setInteresse] = useState(emptyInteresse);

  const fetchInteresse = async () => {
    try {
      let response = await axiosClient.get(
        `/interesses/${selectedIdInteresse}`
      );
      let data = await response.data.data;
      setInteresse(data);
    } catch (error) {
      console.error('Error fetching todos:', error);
    }
  };

  useEffect(() => {
    // Cidade
    selectedIdInteresse !== 0 ? fetchInteresse() : setInteresse(emptyInteresse);
  }, [selectedIdInteresse]);

  const handleClick = (event) => {
    handleDeleteInteresse();
  };
  return (
    <>
      {/* Modal para confirmação de remoção. */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={removerModal.isOpen}
        onClose={removerModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Excluir</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            Confirma a remoção do Interesse: {interesse.nome}?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleClick}>
              Remover
            </Button>
            <Button onClick={removerModal.onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RemoverModalInteresse;
