import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useState } from 'react';
import validationSchema from '../variables/Validation';

const AdicionarFormModal = ({ adicionarModal }) => {
  const initialRef = React.useRef(null);

  let [curso, setCurso] = useState({});

  return (
    <>
      {/* Modal com o formulário de Atualização */}
      <Modal
        initialFocusRef={initialRef}
        isOpen={adicionarModal.isOpen}
        onClose={adicionarModal.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={curso}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                console.log(values);
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <ModalBody pb={6}>
                  {/* Nome */}
                  <FormControl>
                    <FormLabel htmlFor="nome">Nome</FormLabel>
                    <Input
                      type="text"
                      name="nome"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome}
                      ref={initialRef}
                    />
                    <ErrorMessage name="nome" component="div" />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    colorScheme="blue"
                    mr={3}
                  >
                    Salvar
                  </Button>
                  <Button onClick={adicionarModal.onClose}>Cancelar</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdicionarFormModal;
