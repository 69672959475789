import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/react';

import axiosClient from 'api/soutecapi';
import { useInteresse } from '../contexts/InteresseContext';

const QuestoesModal = () => {
  const { selectedIdInteresse, quetoesModal } = useInteresse();

  const [questoes, setQuestoes] = useState([]);

  const fetchInteresseQuestoes = async () => {
    try {
      let response = await axiosClient.get(
        `/interesses/${selectedIdInteresse}/questoes`
      );
      let data = await response.data.data;
      setQuestoes([...data]);
    } catch (error) {
      console.error('Error fetching todos:', error);
    }
  };

  useEffect(() => {
    // Cidade
    selectedIdInteresse !== 0 ? fetchInteresseQuestoes() : setQuestoes([]);
  }, [selectedIdInteresse]);

  return (
    <>
      {/* Modal para confirmação de remoção. */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={quetoesModal.isOpen}
        onClose={quetoesModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cursos</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {questoes.map((questao) => (
              <div>
                <Card
                  direction={{ base: 'column', sm: 'row' }}
                  overflow="hidden"
                  variant="outline"
                  key={questao.id}
                >
                  <Stack>
                    <CardBody>
                      <Heading size="md">{questao.descricao}</Heading>
                    </CardBody>

                    <CardFooter>{/* botões */}</CardFooter>
                  </Stack>
                </Card>
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button onClick={quetoesModal.onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QuestoesModal;
