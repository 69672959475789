import { CalendarIcon, EditIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { scaleQuantile } from "d3-scale";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Tooltip } from "react-tooltip";
import { mapSiglaToCodigo } from "../variables/CodUF";

const ResponseCursos = [
  {
		curso: {
			co_curso: '6073'
		},
		nu_ano_censo: '2023', 
		co_regiao: '1', 
		co_uf: '11', 
		co_mesorregiao: '1101', 
		co_microrregiao: '11001', 
		co_municipio: '1100205',
		qt_mat_ept: 1404
	},
  {
		curso: {
			co_curso: '6073'
		},	
		nu_ano_censo: '2023', 
		co_regiao: '1', 
		co_uf: '25', 
		co_mesorregiao: '1101', 
		co_microrregiao: '11001', 
		co_municipio: '1100205',
		qt_mat_ept: 2000
	},
  {
		curso: {
			co_curso: '6075'
		},	
		nu_ano_censo: '2023', 
		co_regiao: '1', 
		co_uf: '43', 
		co_mesorregiao: '1101', 
		co_microrregiao: '11001', 
		co_municipio: '1100205',
		qt_mat_ept: 2165
	},
]

const optionsCursos = [
  { value: '6073', label: "Analista de Sistemas" },
  { value: '6074', label: "Nutricionista" },
  { value: '6075', label: "Direito" },
]

export const MapaEPT = () => {

  const [selectedOptionCursos, setSelectedOptionCursos] = useState("");
  const [inputYearCenso, setInputYearCenso] = useState("");
  const [filteredCursos, setFilteredCursos] = useState([]);

  const [tooltipContent, setTooltipContent] = useState({ sigla: '', qt_mat_ept: '' });

  const geoUrl = "https://raw.githubusercontent.com/giuliano-macedo/geodata-br-states/main/geojson/br_states.json";

  const handleChangeCurso = (option) => {
    setSelectedOptionCursos(option);
  };

  const handlerFilter = () => {
    const filteredCursos = ResponseCursos.filter((curso) => {
      return selectedOptionCursos && curso.curso.co_curso === selectedOptionCursos.value && curso.nu_ano_censo === inputYearCenso;
    });
    setFilteredCursos(filteredCursos);
  }

  const colorScale = scaleQuantile()
    .domain(filteredCursos.map(curso => curso.qt_mat_ept))
    .range(["#c2e699", "#78c679", "#238443"]);

  useEffect(() => {
    handlerFilter();
  }, [selectedOptionCursos, inputYearCenso])
  return (
    <div className="m-9">
      <div className="flex justify-center">
        <div className="rounded-md outline-none h-20 bg-cardGray w-full mr-6 border border-grayTest">
          <p className="ml-4 my-2 text-sm">Cursos</p>
          <ReactSelect 
            value={selectedOptionCursos}
            onChange={handleChangeCurso}
            options={optionsCursos}
            placeholder="Todos os Cursos"
            isSearchable
            isClearable
            styles={{ 
              container: (base) => ({ ...base, flexGrow: 1 }), 
              control: (styles, { isFocused }) => ({ 
                ...styles, 
                border: "none", 
                boxShadow: isFocused ? "0px 4px 15px rgba(0, 0, 0, 0.2)" : "none", 
                backgroundColor: "#f8f8f8", 
              }),
              dropdownIndicator: styles => ({ ...styles, color: '#333333' }),
              singleValue: (styles) => ({ ...styles, fontWeight: 'bold', fontSize: "20px" }),
              placeholder: (styles) => ({ ...styles, fontWeight: 'bold', fontSize: "20px" }),
              option: (styles) => ({...styles, })
            }}
          />
        </div>
        <div className="rounded-md border border-grayTest outline-none h-20 bg-cardGray w-full">
          <p className="ml-4 my-2 text-sm">Ano do censo</p>
          <div className="w-1/4">
            <InputGroup>
              <Input 
              type='number' 
              placeholder="Ano"
              onChange={(e) => setInputYearCenso(e.target.value)} className="placeholder-placeholderGray font-bold" 
              style={{ boxShadow:"none", border: "none", fontSize: "20px" }}/>
              <InputRightElement pointerEvents='none'>
                <EditIcon color='blue.700' fontSize={20} />
              </InputRightElement>
            </InputGroup>
          </div>
        </div> 
      </div>  
      <div className="h-full w-full">
      <ComposableMap
        projectionConfig={{ 
          scale: 600,
          rotation: [-50, 0, 0],
          center: [-55, -15]
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const siglaState = geo.properties.SIGLA;
              const codigoState = mapSiglaToCodigo[siglaState];
              const cursoFiltrado = filteredCursos.find(curso => curso.co_uf === codigoState )
              return (
                <Geography 
                  key={geo.rsmKey} 
                  geography={geo}
                  onClick={() => {
                    console.log("Clicado no estado:", geo.properties);
                  }}
                  onMouseEnter={() => {
                    setTooltipContent({
                      sigla: geo.properties.SIGLA,
                      qt_mat_ept: cursoFiltrado ? cursoFiltrado.qt_mat_ept : null
                    });
                  }}
                  onMouseLeave={() => {
                    setTooltipContent({ sigla: '', qt_mat_ept: '' });
                  }}
                  style={{
                    default: {
                      fill: cursoFiltrado ? colorScale(cursoFiltrado.qt_mat_ept)  : "#ECEFF1",
                      outline: "none",
                    },
                    hover: {
                      fill: "#CFD8DC",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#B0BEC5",
                      outline: "none"
                    }
                  }}
                    stroke="#000000"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-place="right"
                    // data-tooltip-content={cursoFiltrado ? `${geo.properties.SIGLA} -  Quantidade de Matriculado: ${cursoFiltrado.qt_mat_ept}` : null}
                  />
                )
              })
            }
          </Geographies>
        </ComposableMap>
        <Tooltip id="my-tooltip">
        {tooltipContent.qt_mat_ept ? `${tooltipContent.sigla} - Quantidade de Matriculado: ${tooltipContent.qt_mat_ept}` : ""}
        </Tooltip>
      </div>
    </div>
  )
}