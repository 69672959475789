import { useAuth } from 'contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { signed } = useAuth();

  if (!signed) {
    return <Navigate to="/auth" state={{ path: location.pathname }} />;
  }
  return children;
};

export default RequireAuth;
