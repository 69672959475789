import { createContext, useContext, useState } from "react";
import axiosClient from 'api/soutecapi';

export const EixoTecnologicoContext = createContext({});

export function EixoTecnologicoContextProvider({ children }){

  const [eixosTecnologicos, setEixosTecnologicos] = useState([]);

  async function fetchEixoTecnologico(){
    try{
      let response = await axiosClient.get('/eixostecnologicos')
      let data = await response.data.data
      setEixosTecnologicos([...data]);
      // console.log(data);
      return data
    }catch (error) {
      console.error("Error ao buscar o eixo tecnológico: ", error);
    }
    // axiosClient.get('/eixostecnologicos')
    // .then((response) => {
    //   let eixostecnologicos = response.data.data;
    //   setEixosTecnologicos([...eixostecnologicos]);
    // })
    // return eixosTecnologicos;
  }

  return (
    <EixoTecnologicoContext.Provider
      value={{
        eixosTecnologicos,
        setEixosTecnologicos,
        fetchEixoTecnologico,
      }}>
      {children}
    </EixoTecnologicoContext.Provider>
  )
}

export function useEixotecnologico(){
  return useContext(EixoTecnologicoContext);
}