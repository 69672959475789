import { MdDashboard, MdHome, MdLock } from 'react-icons/md';

// Admin Imports
import EPT from 'views/admin/dashboards/ept';
import LibJSDashboard from 'views/admin/dashboards/libJS';

import Cidades from 'views/admin/main/cidade';
import Estados from 'views/admin/main/estado';
import EixosTecnologicos from 'views/admin/main/eixotecnologico';
import Cursos from 'views/admin/main/curso';
import InstituicoaEnsino from 'views/admin/main/instituicaoensino';
import Interesses from 'views/admin/main/interesse';
import Questao from 'views/admin/main/questao';
import SignInCentered from 'views/auth/signIn/SignInCenter';

const routes = [
  // --- Dashboards ---
  {
    name: 'Dashboards',
    path: '/dashboards',
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: true,
    show: true,
    items: [
      {
        name: 'Recharts',
        show: true,
        layout: '/admin',
        path: '/dashboards/libJS',
        component: <LibJSDashboard />,
      },
      {
        name: 'Ensino Profissionalizante Técnico',
        show: true,
        layout: '/admin',
        path: '/dashboards/powerBI',
        component: <EPT />,
      },
      // {
      //   name: 'Power BI',
      //   path: '/dashboards/powerBI',
      //   collapse: true,
      //   items: [
      //     {
      //       name: 'Perfil Aplicativo',
      //       layout: '/admin',
      //       path: '/dashboards/powerBI/perfil-aplicativo',
      //       exact: false,
      //       component: <EcommerceNewProduct />,
      //     },
      //     {
      //       name: 'Perfil de Interesses',
      //       layout: '/admin',
      //       path: '/dashboards/powerBI/perfil-interesses',
      //       exact: false,
      //       component: <PowerBIPerfilInteresses />,
      //     },
      //     {
      //       name: 'Perfil de Valores',
      //       layout: '/admin',
      //       path: '/dashboards/powerBI/perfil-valores',
      //       exact: false,
      //       component: <EcommerceNewProduct />,
      //     }
      //   ]
      // }
    ],
  },
  // --- Principal ---
  {
    name: 'Principal',
    path: '/main',
    icon: <MdDashboard className="text-inherit h-5 w-5" />,
    collapse: true,
    show: true,
    items: [
      {
        name: 'Cidade',
        show: true,
        layout: '/admin',
        path: '/main/cidade',
        exact: false,
        component: <Cidades />,
      },
      {
        name: 'Estado',
        show: true,
        layout: '/admin',
        path: '/main/estado',
        exact: false,
        component: <Estados />,
      },
      {
        name: 'Eixo Tecnológico',
        show: true,
        layout: '/admin',
        path: '/main/eixotecnologico',
        exact: false,
        component: <EixosTecnologicos />,
      },
      {
        name: 'Curso',
        show: true,
        layout: '/admin',
        path: '/main/curso',
        exact: false,
        component: <Cursos />,
      },
      {
        name: 'Instituição de Ensino',
        show: true,
        layout: '/admin',
        path: '/main/instituicaoensino',
        exact: false,
        component: <InstituicoaEnsino />,
      },
      // {
      //   name: 'Quiz',
      //   layout: '/admin',
      //   path: '/main/quiz',
      //   exact: false,
      //   component: <Quiz />,
      // },
      {
        name: 'Interesses',
        show: true,
        layout: '/admin',
        path: '/main/interesse',
        exact: false,
        component: <Interesses />,
      },
      {
        name: 'Questão',
        show: false,
        layout: '/admin',
        path: '/main/interesse/:id/questao',
        exact: false,
        component: <Questao />,
      },
    ],
  },

  // --- Autenticação ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        show: false,
        layout: '/auth',
        path: '/sign-in',
        exact: false,
        component: <SignInCentered />,
      },
    ],
  },
];
export default routes;
