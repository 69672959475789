import {
  BarChart,
  LabelList,
  Label,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { interessesPorGeneroData } from '../../variables/soutecCharts';
import Card from 'components/card';

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold" />;
};

export default function SBChartInteressesPorGenero() {
  return (
    <Card extra={'h-[381px] pb-8 px-6 pt-6'}>
      <div className="flex justify-between px-3 pt-1">
        <div className="flex items-center">
          <div className="flex flex-col">
            <p className="pb-4 text-[22px] font-bold text-navy-700 dark:text-white">
              {' '}
              Perfil de Interesses x Gênero
            </p>
          </div>
        </div>
      </div>

      {/* Charts */}
      <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="h-full w-full">
          <ResponsiveContainer width="100%" height={275}>
            <BarChart
              layout="vertical"
              data={interessesPorGeneroData}
              margin={{ left: 50, right: 50 }}
              stackOffset="expand"
            >
              <XAxis hide type="number" />
              <YAxis
                type="category"
                dataKey="interesse"
                stroke="#1A202C"
                fontSize="12"
              />
              <Tooltip />
              <Legend />
              <Bar dataKey="feminino" fill="#dd7876" stackId="a">
                <LabelList
                  dataKey="feminino"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey="nao_declarado" fill="#FFC08B" stackId="a">
                <LabelList
                  dataKey="nao_declarado"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey="masculino" fill="#76a8dd" stackId="a">
                <LabelList
                  dataKey="masculino"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
            </BarChart>
            {/* ------- */}
          </ResponsiveContainer>
        </div>
      </div>
    </Card>
  );
}
