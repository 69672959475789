import OpcoesTrTableInteresse from '../components/OpcoesTrTableInteresse';

export const columnsData = [
  {
    name: 'Interesse',
    selector: (row) => row.nome,
    sortable: true,
  },
  {
    name: 'Sigla',
    selector: (row) => row.sigla,
    sortable: true,
  },
  {
    name: 'Descrição',
    selector: (row) => row.descricao,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Opções',
    selector: (row) => <OpcoesTrTableInteresse id={row.id} />,
    sortable: true,
  },
];
