import {
  SearchIcon
} from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { DataTableClient } from 'components/table/DataTableClient';
import { useEffect, useMemo, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import AdicionarFormModal from './AdicionarFormModal';
import { useEixotecnologico } from '../contexts/EixoTecnologicoContext';
import { columnsData } from '../variables/columnsData';

const SearchTable = () => {

  const [filterText, setFilterText] = useState('');

  const { fetchEixoTecnologico } = useEixotecnologico();

  const columns = useMemo(() => columnsData, []);

  // Modal com formulário de atualização.
  const adicionarModal = useDisclosure();

  const handleClickAdicionar = (event) => {
    adicionarModal.onOpen();
  };

  return (
    <div className="h-full w-full">
      {/* Busca */}
      <Flex>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            onChange={(e) => setFilterText(e.target.value)}
            type="text"
            placeholder="Buscar"
          />
        </InputGroup>

        <Stack className="mx-4" direction="row" spacing={4}>
          <Button
            onClick={handleClickAdicionar}
            leftIcon={<MdAdd />}
            variant="solid"
            colorScheme="blue"
          >
            Adicionar
          </Button>
          <AdicionarFormModal adicionarModal={adicionarModal} />
        </Stack>
      </Flex>
      {/* table */}
      <div className="mt-11 h-full w-full overflow-x-scroll xl:overflow-hidden">
        <DataTableClient 
          title={'Eixo Tecnológico'} 
          fetchEntity={fetchEixoTecnologico} 
          columns={columns} 
          filterSearchText={filterText}
        />
      </div>
    </div>
  );
};

export default SearchTable;
