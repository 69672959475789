import Card from 'components/card';
import SearchTable from './components/SearchTable';
import { EixoTecnologicoContextProvider } from './contexts/EixoTecnologicoContext';

const Index = () => {
  return (
    <Card extra={'w-full h-full bg-white mt-3'}>
      {/* Listagem de Eixo Tecnológico */}
      <EixoTecnologicoContextProvider>
        <SearchTable />
      </EixoTecnologicoContextProvider>
    </Card>
  );
};

export default Index;
