import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Digite um e-mail válido.')
    .test('lowercase-email', 'Digite um e-mail válido', function (value) {
      return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/g.test(value);
    })
    .required('Campo obrigatório.'),
  password: Yup.string()
    .trim()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      `Deve conter no mínimo 8 caracteres, uma letra maiúscula, uma 
        letra minúscula, um número e um caractere especial`
    )
    .required('Campo obrigatório.'),
});

export default validationSchema;
