import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import axiosClient from 'api/soutecapi';
import { ErrorMessage, Form, Formik, Field } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';

import validationSchema from '../variables/Validation';
import { useInteresse } from '../contexts/InteresseContext';

const EditarFormModalInteresse = () => {
  const {
    emptyInteresse,
    estados,
    selectedIdInteresse,
    editarModal,
    handleSubmitUpdateInteresseForm,
  } = useInteresse();

  let [interesse, setInteresse] = useState(emptyInteresse);

  // handle selection
  const handleChangeEstado = (option, values, setValues) => {
    setValues({ ...values, estado: { ...option } });
  };

  const fetchInteresse = async () => {
    try {
      let response = await axiosClient.get(
        `/interesses/${selectedIdInteresse}`
      );
      let data = await response.data.data;
      setInteresse({ ...data });
    } catch (error) {
      console.error('Error fetching todos:', error);
    }
  };

  useEffect(() => {
    // Cidade
    selectedIdInteresse !== 0 ? fetchInteresse() : setInteresse(emptyInteresse);
  }, [selectedIdInteresse]);

  return (
    <>
      {/* Modal com o formulário de Atualização */}
      <Modal
        isOpen={editarModal.isOpen}
        onClose={editarModal.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={interesse}
            validationSchema={validationSchema}
            onSubmit={handleSubmitUpdateInteresseForm}
            enableReinitialize={true}
          >
            {({
              values,
              setValues,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <ModalBody pb={6}>
                  {/* Nome */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="nome">Nome</FormLabel>
                    <Input
                      type="text"
                      name="nome"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome}
                    />
                    <ErrorMessage name="nome" component="div" />
                  </FormControl>

                  {/* Capital */}
                  <FormControl>
                    <FormLabel htmlFor="capital">Capital</FormLabel>
                    <Stack spacing={5} direction="row">
                      <Field
                        as={Checkbox}
                        id="capital"
                        name="capital"
                        value={values.capital}
                        isChecked={values.capital}
                        onChange={handleChange}
                      >
                        Capital?
                      </Field>
                      <FormHelperText>A cidade é uma capital?</FormHelperText>
                      <ErrorMessage name="capital" component="div" />
                    </Stack>
                  </FormControl>

                  {/*TODO: Estado */}
                  <Select
                    value={values.estado}
                    options={estados}
                    getOptionLabel={(e) => e.nome}
                    getOptionValue={(e) => e.id}
                    onChange={(option) =>
                      handleChangeEstado(option, values, setValues)
                    }
                  />

                  {/* Latitude */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="latitude">Latitude</FormLabel>
                    <Input
                      type="text"
                      name="latitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.latitude}
                    />
                    <ErrorMessage name="latitude" component="div" />
                  </FormControl>

                  {/* Longitude */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="longitude">Longitude</FormLabel>
                    <Input
                      type="text"
                      name="longitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.longitude}
                    />
                    <ErrorMessage name="longitude" component="div" />
                  </FormControl>
                  {/* Fuso horário */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="fuso_horario">Fuso horário</FormLabel>
                    <Input
                      type="text"
                      name="fuso_horario"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fuso_horario}
                    />
                    <ErrorMessage name="fuso_horario" component="div" />
                  </FormControl>
                  {/* DDD */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="ddd">DDD</FormLabel>
                    <Input
                      type="text"
                      name="ddd"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ddd}
                    />
                    <ErrorMessage name="ddd" component="div" />
                  </FormControl>
                  {/* Código IBGE */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="codigo_ibge">Código IBGE</FormLabel>
                    <Input
                      type="text"
                      name="codigo_ibge"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.codigo_ibge}
                    />
                    <ErrorMessage name="codigo_ibge" component="div" />
                  </FormControl>
                  {/* Siafi */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="siafi_id">Siafi</FormLabel>
                    <Input
                      type="text"
                      name="siafi_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.siafi_id}
                    />
                    <ErrorMessage name="siafi_id" component="div" />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    colorScheme="blue"
                    mr={3}
                  >
                    Salvar
                  </Button>
                  <Button onClick={editarModal.onClose}>Cancelar</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarFormModalInteresse;
