import { Button } from '@chakra-ui/react';
import { TfiReload } from 'react-icons/tfi';

import './ErrorPage.css';

export default function ErrorPage(props) {
  return (
    <div className={'error-page'}>
      <div className={'oops'}>Oops!</div>
      <div className={'message'}>Alguma coisa parece errada...</div>
      {props.resetErrorBoundary && (
        <div>
          <Button
            onClick={props.resetErrorBoundary}
            leftIcon={<TfiReload />}
            variant="link"
            colorScheme="green"
          >
            Tente novamente!
          </Button>
        </div>
      )}
    </div>
  );
}
