import OpcoesTrTable from '../components/OpcoesTrTable';

export const columnsData = [
  {
    name: 'Curso',
    selector: (row) => row.nome,
  },
  {
    name: 'Eixo Tecnológico',
    selector: (row) => row.eixoTecnologico.nome,
  },
  {
    name: 'Carga Horária',
    selector: (row) => row.cargaHoraria.horas,
  },

  {
    name: 'Opções',
    selector: (row) => <OpcoesTrTable id={row.id} />,
  },
];
