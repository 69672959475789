import { Wrap, WrapItem } from '@chakra-ui/react';
import DeleteButton from 'components/button/DeleteButton';
import EditButton from 'components/button/EditButton';
import { useQuestao } from '../contexts/QuestaoContext';

const OpcoesTrTableQuestao = ({ id }) => {
  const {
    handleLoadUpdateQuestaoForm,
    handleLoadDeleteQuestaoModal,
  } = useQuestao();

  const handleClickEditar = (event) => {
    handleLoadUpdateQuestaoForm(id);
  };

  const handleClickRemover = (event) => {
    handleLoadDeleteQuestaoModal(id);
  };

  return (
    <>
      <Wrap spacing={2}>
        <WrapItem>
          <DeleteButton handleClick={handleClickRemover}>
            Remover
          </DeleteButton>
        </WrapItem>

        <WrapItem>
          <EditButton handleClick={handleClickEditar}>
            Editar
          </EditButton>
        </WrapItem>
      </Wrap>
    </>
  );
};

export default OpcoesTrTableQuestao;
