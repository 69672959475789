import { createContext, useContext, useState } from "react";
import axiosClient from 'api/soutecapi';
import { useDisclosure } from "@chakra-ui/hooks";

export const EstadoContext = createContext({});

export function EstadoContextProvider({ children }) {
  // Estados
  const [estados, setEstados] = useState([]);
  const [selectedIdEstado, setSelectedIdEstado] = useState(0);
  console.log(estados);

  const emptyEstado = {
    id: 0,
    codigo_uf: 0,
    latitude: "",
    longitude: "",
    nome: '',
    regiao: '',
    uf: '',
  };

  // Modal com exibição do mapa.
  const exibirMapaModal = useDisclosure();

  // Modal com formulário de edição.
  const editarModal = useDisclosure();

  // Modal com botão de remoção de item.
  const removerModal = useDisclosure();

  async function fetchEstados() {
    try {
      let response = await axiosClient.get('/estados');
      let data = await response.data.data;
      setEstados([...data]);
      return data
    } catch (error) {
      console.error("Error ao buscar o estado: ", error);
    }
  }

  async function fetchEstadoId(id) {
    let response = await axiosClient.get(`/estados/${id}`);
    console.log(response);
    let estado = response.data.data;
    setEstados({ ...estado });
    return estado;
  }

  const handleLoadMapCidadeModal = (id) => {
    // Item selecionado.
    setSelectedIdEstado(id);

    // Exibir Modal de exibição do mapa.
    exibirMapaModal.onOpen();
  };
  const handleLoadUpdateEstadoForm = (id) => {
    // Item selecionado.
    setSelectedIdEstado(id);

    // Exibir Modal do formulário.
    editarModal.onOpen();
  }

  const handleLoadDeleteEstadoModal = (id) => {
    // Item selecionado.
    setSelectedIdEstado(id);

    // Exibir Modal de remoção.
    removerModal.onOpen();
  }

  return (
    <EstadoContext.Provider 
      value={{
        estados,
        setEstados,
        emptyEstado,
        fetchEstados,
        fetchEstadoId,
        handleLoadMapCidadeModal,
        handleLoadUpdateEstadoForm,
        handleLoadDeleteEstadoModal,
      }}>
      {children}
    </EstadoContext.Provider>
  );
}

export function useEstado() {
  return useContext(EstadoContext);
}
