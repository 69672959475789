import applyMask from 'components/cnpj';
import OpcoesTrTable from '../components/OpcoesTrTable';

export const columnsData = [
  {
    name: 'Instituição de Ensino',
    selector:(row) =>row.nome,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Cnpj',
    selector:(row) =>row.cnpj ? applyMask(row.cnpj) : '-',
    sortable: true,
  },
  {
    name: 'Inep',
    selector:(row) =>row.codInep ? row.codInep : '-',
    sortable: true,
  },
  {
    name: 'Sistema de Ensino',
    selector:(row) =>row.sistema_ensino,
    sortable: true,
  },
  {
    name: 'Tipo Escola',
    selector:(row) =>row.tipo_escola,
    sortable: true,
  },
  {
    name: 'Dependência',
    selector:(row) =>row.dependencia,
    sortable: true,
  },
  {
    name: 'Situação MEC',
    selector:(row) =>row.situacao_ativo,
    sortable: true,
  },
  {
    name: 'Opções',
    selector:(row) => <OpcoesTrTable id={row.id}/>,
    sortable: true,
  },
];
