import { useDisclosure } from '@chakra-ui/hooks';
import { createContext, useState, useContext } from 'react';
import axiosClient from 'api/soutecapi';
import { useToastHook } from 'components/toast';

export const CidadeContext = createContext({});

export function CidadeContextProvider({ children }) {
  const noSelectIdCidade = 0;

  const emptyCidade = {
    id: 0,
    nome: '',
    capital: false,
    latitude: '',
    longitude: '',
    estado: { id: 0, uf: '', nome: '' },
    fuso_horario: '',
    ddd: '',
    codigo_ibge: '',
    siafi_id: '',
  };

  // Cidades
  const [cidades, setCidades] = useState([]);

  // Cidades
  const [selectedCidade, setSelectedCidade] = useState(emptyCidade);

  // Cidade selecionada.
  const [selectedIdCidade, setSelectedIdCidade] = useState(0);

  // Modal com formulário de edição.
  const editarModal = useDisclosure();

  // Modal com botão de remoção de item.
  const removerModal = useDisclosure();

  // Modal com exibição do mapa.
  const exibirMapaModal = useDisclosure();

  const [toast, setToast] = useToastHook();

  const fetchCidades = async () => {
    try {
      let response = await axiosClient.get('/cidades');
      let data = await response.data.data;
      setCidades([...data]);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getData = async (page = 1, perPage = 10) => {
    try {
      let response = await axiosClient.get(
        `/cidades/?page=${page}&limit=${perPage}`
      );
      let data = await response.data;
      return data;
    } catch (error) {
      console.log(error.response);
    }
  };

  const fetchCidade = async () => {
    try {
      let response = await axiosClient.get(`/cidades/${selectedIdCidade}`);
      let data = await response.data.data;
      setSelectedCidade([...data]);
    } catch (error) {
      console.error(error.response);
    }
  };

  const updateCidade = async (values) => {
    try {
      // Atualizar.
      let response = await axiosClient.put(
        `/cidades/${selectedIdCidade}`,
        values
      );
      let data = await response.data.data;

      //Retornar item atualizado.
      return data;
    } catch (error) {
      console.error(error.response);
    }
  };

  const updateItensTableCidades = (novaCidade) => {
    const novasCidades = cidades.map((itemCidade) => {
      return itemCidade.id === novaCidade.id ? { ...novaCidade } : itemCidade;
    });

    setCidades([...novasCidades]);
  };

  const handleSubmitUpdateCidadeForm = async (values, { setSubmitting }) => {
    try {
      // Atualizar os dados no endpoint do servidor.
      let novaCidade = await updateCidade(values);

      // Atualizar listagem
      updateItensTableCidades(novaCidade);

      //Reiniciar formulário.
      setSelectedIdCidade(noSelectIdCidade);

      // Fechar modal.
      editarModal.onClose();

      // Mensagem de sucesso.
      setToast({
        title: 'Cidade',
        message: 'Atualizada com sucesso.',
        status: 'success',
      });
    } catch (error) {
      setToast({
        title: 'Cidade',
        message: 'Atualização não efetuada.',
        status: 'error',
      });
    }
  };

  const handleDeleteCidade = async () => {
    try {
      //TODO: Remover item no endpoint do servidor.
      await axiosClient.delete(`/cidades/${selectedIdCidade}`);

      // Remover item.
      const novasCidades = cidades.filter(
        (cidadeItem) => cidadeItem.id !== selectedIdCidade
      );

      // Atualizar itens.
      setCidades([...novasCidades]);

      // Reiniciar id do context.
      setSelectedIdCidade(noSelectIdCidade);

      // Fechar modal.
      removerModal.onClose();

      setToast({
        title: 'Cidade',
        message: 'Removida com sucesso',
        status: 'success',
      });
    } catch (error) {
      setToast({
        title: 'Cidade',
        message: 'Remoção não efetuada.',
        status: 'error',
      });
    }
  };

  const handleLoadUpdateCidadeForm = (id) => {
    // Item selecionado.
    setSelectedIdCidade(id);

    // Exibir Modal do formulário.
    editarModal.onOpen();
  };

  const handleLoadDeleteCidadeModal = (id) => {
    // Item selecionado.
    setSelectedIdCidade(id);

    // Exibir Modal de remoção.
    removerModal.onOpen();
  };

  const handleLoadMapCidadeModal = (id) => {
    // Item selecionado.
    setSelectedIdCidade(id);

    // Exibir Modal de exibição do mapa.
    exibirMapaModal.onOpen();
  };

  return (
    <CidadeContext.Provider
      value={{
        emptyCidade,
        cidades,
        setCidades,
        selectedCidade,
        setSelectedCidade,
        selectedIdCidade,
        setSelectedIdCidade,
        editarModal,
        removerModal,
        exibirMapaModal,
        getData,
        fetchCidades,
        fetchCidade,
        handleLoadUpdateCidadeForm,
        handleLoadDeleteCidadeModal,
        handleSubmitUpdateCidadeForm,
        handleDeleteCidade,
        handleLoadMapCidadeModal,
      }}
    >
      {children}
    </CidadeContext.Provider>
  );
}

export function useCidade() {
  return useContext(CidadeContext);
}
