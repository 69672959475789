import { Card } from "@chakra-ui/react";
import { MapaEPT } from "./components/MapaEPT";

const Ept = () => {
  return (
    <Card extra={'w-full h-full bg-white mt-3'}>
      <MapaEPT />
    </Card>
  )
};

export default Ept;
