import Progress from "components/progress";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";

export const DataTableClient = ({ title, columns, fetchEntity, filterSearchText }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const fetchData = async () => {
    setLoading(true)
    const responseData = await fetchEntity()
    setData(responseData)
    setLoading(false)
  }

  const dataMemorized = useMemo(() => data, [data]);

  let filteredDate = Array.isArray(dataMemorized) ? dataMemorized.filter(entity => {
    return Object.values(entity).some(valor => 
      valor.toString().toLowerCase().includes(filterSearchText.toLowerCase())
    );
  }) : [];

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <DataTable 
      title={title} 
      columns={columns} 
      data={filteredDate}
      progressPending={loading}
      progressComponent={<Progress />}
      pagination
    />
  );
}